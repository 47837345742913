import { withNavigationWatcher } from './contexts/navigation';
import Home from './pages/Home/Home';
// import Games from './pages/Games/Games';
// import Purchases from './pages/Purchases/Purchases';
import Orders from './pages/Orders/Orders';
import Archive from './pages/Archive/Archive';
import Refills from './pages/Refills/Refills';
import AdminUsers from './pages/AdminUsers/AdminUsers';
import Banners from './pages/Banners/Banners';
import Users from './pages/Users/Users';

const routes = [
    {
        path: '/games',
        element: Home
    },
    {
        path: '/',
        element: Orders
    },
    {
        path: '/profiles',
        element: Users
    },
    {
        path: '/archive',
        element: Archive
    },
    {
        path: '/banners',
        element: Banners
    },
    {
        path: '/users',
        element: AdminUsers
    },
    {
        path: '/refills',
        element: Refills
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
