import React, { useState, useEffect } from 'react';
import { getAllAdminUsers, deleteAdminUser, updateAdminUser, registerAdminUser } from '../../api/apiServiceFetch';
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    Paging,
    Pager,
    Toolbar,
    Item as ToolbarItem
} from 'devextreme-react/data-grid';
import { Button as DxButton } from 'devextreme-react/button';
import Popup from 'devextreme-react/popup';
import Switch from 'devextreme-react/switch';
import 'devextreme/dist/css/dx.light.css';
import './AdminUsers.scss';

const AdminUsers = () => {
    const [adminUsers, setAdminUsers] = useState([]);

    // Add User State (Register)
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [newAdminUser, setNewAdminUser] = useState({
        fullName: '',
        userName: '',
        password: '',
        retypePassword: '',
        isActive: false
    });

    // Edit User State (Update)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editUser, setEditUser] = useState(null);

    // Delete User State
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);

    // Change Password State (not implemented on DTO)
    const [isChangePasswordPopupOpen, setIsChangePasswordPopupOpen] = useState(false);
    const [passwordUser, setPasswordUser] = useState(null);
    const [newPassword, setNewPassword] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const users = await getAllAdminUsers();
            setAdminUsers(users);
        } catch (error) {
            console.error('Failed to load admin users:', error);
        }
    };

    // Add User Functions (Register)
    const handleAddUserClick = () => {
        setIsAddModalOpen(true);
    };

    const closeAddUserModal = () => {
        setIsAddModalOpen(false);
        setNewAdminUser({ fullName: '', userName: '', password: '', retypePassword: '', isActive: false });
    };

    const handleInsert = async () => {
        const { fullName, userName, password, retypePassword, isActive } = newAdminUser;

        try {
            const response = await registerAdminUser({
                fullName,
                userName,
                password,
                retypePassword,
                isActive
            });

            await fetchUsers();
            closeAddUserModal();

        } catch (error) {
            console.error('Error registering new user:', error);
        }
    };

    // Edit User Functions (Update)
    const handleEditClick = (user) => {
        setEditUser({
            adminUserId: user.adminUserId,
            fullName: user.fullName || '',
            userName: user.userName || '',
            login: user.login || user.userName || '',
            isActive: user.isActive ?? false,
            isSuperAdmin: user.isSuperAdmin ?? false
        });
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setEditUser(null);
    };

    const handleUpdateUser = async () => {
        if (!editUser) return;
        try {
            await updateAdminUser({
                adminUserId: editUser.adminUserId,
                fullName: editUser.fullName,
                userName: editUser.userName,
                login: editUser.login,
                isActive: editUser.isActive,
                isSuperAdmin: editUser.isSuperAdmin
            });
            await fetchUsers();
        } catch (error) {
            console.error('Error updating user:', error);
        }
        closeEditModal();
    };

    // Toggle Active / SuperAdmin directly from row switch
    const toggleIsActive = async (user, newValue) => {
        // Directly use newValue instead of inverting the old value
        const updatedUser = { ...user, isActive: newValue };
        try {
            await updateAdminUser({
                adminUserId: user.adminUserId,
                fullName: user.fullName,
                userName: user.userName,
                login: user.login || user.userName,
                isActive: newValue, // Use newValue directly
                isSuperAdmin: user.isSuperAdmin ?? false
            });
            setAdminUsers((prevUsers) =>
                prevUsers.map((u) => (u.adminUserId === user.adminUserId ? updatedUser : u))
            );
        } catch (error) {
            console.error('Failed to update user isActive:', error);
        }
    };


    const toggleIsSuperAdmin = async (user, newValue) => {
        const updatedUser = { ...user, isSuperAdmin: newValue };
        try {
            await updateAdminUser({
                adminUserId: user.adminUserId,
                fullName: user.fullName,
                userName: user.userName,
                login: user.login || user.userName,
                isActive: user.isActive ?? false,
                isSuperAdmin: newValue
            });
            setAdminUsers((prevUsers) =>
                prevUsers.map((u) => (u.adminUserId === user.adminUserId ? updatedUser : u))
            );
        } catch (error) {
            console.error('Failed to update user isSuperAdmin:', error);
        }
    };


    // Delete User Functions
    const handleDeleteClick = (user) => {
        setUserToDelete(user);
        setIsDeletePopupOpen(true);
    };

    const confirmDelete = async () => {
        if (userToDelete) {
            try {
                await deleteAdminUser({ adminUserId: userToDelete.adminUserId });
                setAdminUsers(adminUsers.filter(u => u.adminUserId !== userToDelete.adminUserId));
            } catch (error) {
                console.error('Delete error:', error);
            }
        }
        setIsDeletePopupOpen(false);
        setUserToDelete(null);
    };

    const cancelDelete = () => {
        setIsDeletePopupOpen(false);
        setUserToDelete(null);
    };

    // Change Password Functions (Not implemented)
    const handleChangePasswordClick = (user) => {
        setPasswordUser(user);
        setNewPassword('');
        setIsChangePasswordPopupOpen(true);
    };

    const closeChangePasswordPopup = () => {
        setIsChangePasswordPopupOpen(false);
        setPasswordUser(null);
        setNewPassword('');
    };

    const confirmChangePassword = async () => {
        console.warn('Change password endpoint not implemented. Create a separate endpoint & DTO for this.');
        closeChangePasswordPopup();
    };

    return (
        <div className="AdminUsers" style={{ padding: '20px' }}>
            <h2>Администраторы</h2>

            <DataGrid
                dataSource={adminUsers}
                keyExpr="adminUserId"
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
            >
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} />

                <Toolbar>
                    <ToolbarItem location="after">
                        <DxButton
                            text="Добавить"
                            icon="add"
                            onClick={handleAddUserClick}
                        />
                    </ToolbarItem>
                </Toolbar>

                <Column dataField="fullName" caption="Полное имя" />
                <Column dataField="userName" caption="Имя пользователя" />
                <Column
                    dataField="isActive"
                    caption="Активный"
                    cellRender={(cellInfo) => (
                        <input
                            type="checkbox"
                            checked={cellInfo.value}
                            onChange={(e) => toggleIsActive(cellInfo.data, e.target.checked)}
                        />
                    )}
                />
                <Column
                    dataField="isSuperAdmin"
                    caption="Супер Админ?"
                    cellRender={(cellInfo) => (
                        <input
                            type="checkbox"
                            checked={cellInfo.data.isSuperAdmin ?? false}
                            onChange={(e) => toggleIsSuperAdmin(cellInfo.data, e.target.checked)}
                        />
                    )}
                />





                <Column dataField="lastLoginDate" caption="Последний вход" dataType="datetime" format={{
                    type: 'datetime',
                    formatter: (date) => {
                        const day = String(date.getDate()).padStart(2, '0');
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const year = date.getFullYear();
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        return `${day}/${month}/${year} ${hours}:${minutes}`;
                    }
                }} />
                <Column dataField="createDate" caption="Дата создания" dataType="datetime" format={{
                    type: 'datetime',
                    formatter: (date) => {
                        const day = String(date.getDate()).padStart(2, '0');
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const year = date.getFullYear();
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        return `${day}/${month}/${year} ${hours}:${minutes}`;
                    }
                }} />

                <Column
                    caption="Действия"
                    cellRender={(cellInfo) => (
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <DxButton
                                text="Редактировать"
                                icon="edit"
                                onClick={() => handleEditClick(cellInfo.data)}
                            />
                            <DxButton
                                text="Сменить пароль"
                                icon="key"
                                onClick={() => handleChangePasswordClick(cellInfo.data)}
                            />
                            <DxButton
                                text="Удалить"
                                type="danger"
                                icon="trash"
                                onClick={() => handleDeleteClick(cellInfo.data)}
                            />
                        </div>
                    )}
                />
            </DataGrid>

            {/* Add User Popup (Register) */}
            <Popup
                visible={isAddModalOpen}
                onHiding={closeAddUserModal}
                dragEnabled={false}
                showCloseButton={true}
                showTitle={true}
                title="Добавить пользователя"
                width={350}
                height={"auto"}
            >
                <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <input
                        type="text"
                        placeholder="Full Name"
                        value={newAdminUser.fullName}
                        onChange={(e) => setNewAdminUser({ ...newAdminUser, fullName: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="User Name"
                        value={newAdminUser.userName}
                        onChange={(e) => setNewAdminUser({ ...newAdminUser, userName: e.target.value })}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={newAdminUser.password}
                        onChange={(e) => setNewAdminUser({ ...newAdminUser, password: e.target.value })}
                    />
                    <input
                        type="password"
                        placeholder="Retype Password"
                        value={newAdminUser.retypePassword}
                        onChange={(e) => setNewAdminUser({ ...newAdminUser, retypePassword: e.target.value })}
                    />
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <label>Активный:</label>
                        <Switch
                            value={newAdminUser.isActive}
                            onValueChanged={(val) => setNewAdminUser({ ...newAdminUser, isActive: val.value })}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: '10px' }}>
                        <DxButton text="Подтвердить" type="success" onClick={handleInsert} />
                        <DxButton text="Отменить" onClick={closeAddUserModal} />
                    </div>
                </div>
            </Popup>

            {/* Edit User Popup (Update) */}
            <Popup
                visible={isEditModalOpen}
                onHiding={closeEditModal}
                dragEnabled={false}
                showCloseButton={true}
                showTitle={true}
                title="Редактировать пользователя"
                width={350}
                height={"auto"}
            >
                {editUser ? (
                    <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '10px',  height: 'auto'}}>
                        <input
                            type="text"
                            placeholder="Full Name"
                            value={editUser.fullName}
                            onChange={(e) => setEditUser({ ...editUser, fullName: e.target.value })}
                        />
                        <input
                            type="text"
                            placeholder="User Name"
                            value={editUser.userName}
                            onChange={(e) => setEditUser({ ...editUser, userName: e.target.value })}
                        />
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <label>Активный:</label>
                            <Switch
                                value={editUser.isActive}
                                onValueChanged={(val) => setEditUser({ ...editUser, isActive: val.value })}
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <label>Супер Админ?:</label>
                            <Switch
                                value={editUser.isSuperAdmin}
                                onValueChanged={(val) => setEditUser({ ...editUser, isSuperAdmin: val.value })}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: '10px' }}>
                            <DxButton text="Обновить" type="success" onClick={handleUpdateUser} />
                            <DxButton text="Отменить" onClick={closeEditModal} />
                        </div>
                    </div>
                ) : (
                    <div style={{ padding: '20px' }}>
                        <p>Загрузка данных пользователя...</p>
                    </div>
                )}
            </Popup>

            {/* Delete Confirmation Popup */}
            <Popup
                visible={isDeletePopupOpen}
                onHiding={cancelDelete}
                dragEnabled={false}
                showCloseButton={true}
                showTitle={true}
                title="Подтвердите удаление"
                width={350}
                height={"auto"}
            >
                <div style={{ padding: '20px' }}>
                    <p>Вы уверены, что хотите удалить этого пользователя?</p>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: '10px' }}>
                        <DxButton text="Отмена" onClick={cancelDelete} />
                        <DxButton text="Удалить" type="danger" onClick={confirmDelete} />
                    </div>
                </div>
            </Popup>

            {/* Change Password Popup (not implemented) */}
            <Popup
                visible={isChangePasswordPopupOpen}
                onHiding={closeChangePasswordPopup}
                dragEnabled={false}
                showCloseButton={true}
                showTitle={true}
                title="Смена пароля"
                width={350}
                height={"auto"}
            >
                <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <h3>Смена пароля</h3>
                    <input
                        type="password"
                        placeholder="Новый пароль"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: '10px' }}>
                        <DxButton text="Отмена" onClick={closeChangePasswordPopup} />
                        <DxButton
                            text="Подтвердить"
                            type="success"
                            onClick={confirmChangePassword} // Not implemented endpoint
                        />
                    </div>
                </div>
            </Popup>
        </div>
    );
};

export default AdminUsers;
