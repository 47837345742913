import React, { useState, useEffect } from "react";
import {
    getUserOrderRefills, getUserPurchase
} from "../../api/apiServiceFetch";
import TG from "../../assets/tg.png";

import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    Paging,
    Pager,
    Toolbar,
    LoadPanel,
    Selection,
    Item as ToolbarItem
} from "devextreme-react/data-grid";
import { Button as DxButton } from "devextreme-react/button";
import "./Refills.scss"
import "devextreme/dist/css/dx.light.css";

const filePath = process.env.REACT_APP_FILE_PATH;
const statusMapping = {
    0: "Ожидание",
    1: "Оплачено",
    2: "Отклонено",
    3: "СДЕЛАНО",
    4: "Ожидание",
    5: "Оплачено",
    6: "Отклонено",
};

const statusStyles = {
    0: { backgroundColor: "orange", color: "white", borderRadius: "5px" },
    1: { backgroundColor: "green", color: "white", borderRadius: "5px" },
    2: { backgroundColor: "red", color: "white", borderRadius: "5px" },
    3: { backgroundColor: "blue", color: "white", borderRadius: "5px" },
    4: { backgroundColor: "orange", color: "white", borderRadius: "5px" },
    5: { backgroundColor: "green", color: "white", borderRadius: "5px" },
    6: { backgroundColor: "red", color: "white", borderRadius: "5px" }
};

const formatDate = (dateString) => {
    if (!dateString) return "";

    // Parse the date string and adjust to Moscow time (UTC+3)
    const armenianDate = new Date(dateString); // Assuming the input is in UTC+4
    const moscowOffset = -1 * 60 * 60 * 1000; // UTC+3 is 1 hour behind UTC+4
    const moscowDate = new Date(armenianDate.getTime() + moscowOffset);

    return `${moscowDate.getFullYear()}/${String(moscowDate.getMonth() + 1).padStart(2, "0")
        }/${String(moscowDate.getDate()).padStart(2, "0")} ${String(moscowDate.getHours()).padStart(2, "0")
        }:${String(moscowDate.getMinutes()).padStart(2, "0")}`;
};

const Refills = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userOrders, setUserOrders] = useState([]);
    const [loadingUserOrders, setLoadingUserOrders] = useState(false);

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const orderData = await getUserOrderRefills();
            setOrders(orderData);
        } catch (error) {
            console.error("Error fetching orders:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserOrders = async (orderId) => {
        setLoadingUserOrders(true);
        try {
            const userOrderData = await getUserPurchase(orderId);
            setUserOrders(userOrderData);
        } catch (error) {
            console.error("Error fetching user orders:", error);
        } finally {
            setLoadingUserOrders(false);
        }
    };


    useEffect(() => {
        fetchOrders();
    }, []);

    return (
        <div style={{ width: "100%" }}>
            <h2>Пополнения баланса</h2>
            {/* Primary DataGrid */}
            <div>
                <DataGrid
                    dataSource={orders}
                    keyExpr="orderId"
                    showBorders={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    paging={{ pageSize: 10 }}
                    onRowClick={(e) => fetchUserOrders(e.data.orderId)}
                    loading={loading}
                    columnChooser={true}
                    columnHidingEnabled={true}
                >
                    <LoadPanel enabled={loading} text="Загрузка..." />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <Toolbar>
                        <ToolbarItem location="after">
                            <DxButton hint="Обновить" icon="refresh" onClick={fetchOrders} />
                        </ToolbarItem>
                    </Toolbar>
                    <Selection mode="single" />
                    <Column width={100} dataField="orderId" caption="Код платежа" />
                    <Column
                        width={100}
                        dataField="state"
                        caption="Статус"
                        cellRender={(cellInfo) => (
                            <div
                                style={{
                                    ...statusStyles[cellInfo.data.state],
                                    padding: "5px",
                                    textAlign: "center",
                                }}
                            >
                                {statusMapping[cellInfo.data.state] || "Неизвестный статус"}
                            </div>
                        )}
                    />
                    <Column
                        dataField="invoiceId" caption="Инвоис" />
                    <Column
                        width={100} dataField="userId" caption="Код покупателя" />
                    <Column
                        dataField="userName"
                        caption="Телеграм"
                        cellRender={(cellInfo) => {

                            if (cellInfo.data.userName == "no username") {
                                return <p style={{
                                    margin: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "red",
                                }}>-</p>;
                            }
                            const telegramUrl = `https://t.me/${cellInfo.data.userName}`;

                            return (
                                <a
                                    href={telegramUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: "none",
                                        display: "inline-flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <img
                                        src={TG}
                                        alt="Telegram"
                                        style={{ width: "20px", height: "20px", marginRight: "5px" }}
                                    />
                                    {/* If you only want the icon and not the username text, remove the span below */}
                                    <span style={{ color: "#1d9bf0", fontWeight: "bold" }}>
                                        {cellInfo.data.userName}
                                    </span>
                                </a>
                            );
                        }}
                    />
                    <Column
                        width={150} dataField="payAmount" caption="Сумма пополнения" />
                    <Column
                        width={150}
                        dataField="cDate"
                        caption="Дата оплаты"
                        cellRender={(cellInfo) => formatDate(cellInfo.data.cDate)}
                    />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} />
                </DataGrid>
            </div>


        </div>
    );
};

export default Refills;
