import React, { useState, useEffect } from "react";

import TG from "../../assets/tg.png";
import { getAllUsers, AddOrderBalance, blockUser } from "../../api/apiServiceFetch";
import { DataGrid, GridSearchIcon } from "@mui/x-data-grid";
import {
    Button,
    TextField,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Select,
    FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit"; // Correct import
import PopupMessage from "../../components/PopupMessage";
import ConfirmPopup from '../../components/ConfirmPopup';
import "./Users.scss";

const Users = () => {
    const filePath = process.env.REACT_APP_FILE_PATH;
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [isPopupMessageOpen, setIsPopupMessageOpen] = useState(false);
    const [msgType, setMsgType] = useState();
    const [searchQuery, setSearchQuery] = useState(""); // Add state for search query
    const [paginationModel, setPaginationModel] = useState({
        page: 0, // Zero-based index
        pageSize: 20, // Default page size
    });


    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [formData, setFormData] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState("");
    const [presentDialogOpen, setPresentDialogOpen] = useState(false);
    const [presentAmount, setPresentAmount] = useState(0);
    const [showPopup, setShowPopup] = useState(false);

    const openPresentDialog = (userId) => {
        setSelectedUser(userId);
        setPresentDialogOpen(true);
    };

    const closePresentDialog = () => {
        setPresentDialogOpen(false);
        setPresentAmount(0);
    };

    const confirmGivePresent = () => {
        handleGivePresent(selectedUser, presentAmount);
        closePresentDialog();
    };
    const formatDate = (dateString) => {
        if (!dateString) return "";

        // Parse the date string and adjust to Moscow time (UTC+3)
        const armenianDate = new Date(dateString); // Assuming the input is in UTC+4
        const moscowOffset = -1 * 60 * 60 * 1000; // UTC+3 is 1 hour behind UTC+4
        const moscowDate = new Date(armenianDate.getTime() + moscowOffset);

        return `${moscowDate.getFullYear()}/${String(moscowDate.getMonth() + 1).padStart(2, "0")
            }/${String(moscowDate.getDate()).padStart(2, "0")} ${String(moscowDate.getHours()).padStart(2, "0")
            }:${String(moscowDate.getMinutes()).padStart(2, "0")}`;
    };


    const fetchAllUsers = async (page, pageSize, searchQuery) => {
        try {
            const response = await getAllUsers(page + 1, pageSize, searchQuery);
            setTotalCount(response.totalCount);
            setUsers(response.data);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    useEffect(() => {
        fetchAllUsers(paginationModel.page, paginationModel.pageSize, searchQuery);
    }, [paginationModel, searchQuery]);

    const closeMessagePopup = () => {
        setIsPopupMessageOpen(false);
    };

    const handleAllMasage = () => {
        setMsgType(0)
        setIsPopupMessageOpen(true);
    };
    const handleMasage = () => {
        setMsgType(1)
        setIsPopupMessageOpen(true);
    };
    const handlePaginationChange = (model) => {
        console.log("Pagination model changed:", model); // Debugging
        setPaginationModel(model);
    };

    const handleGivePresent = async (userId, topUpAmount) => {
        const data = {
            orderId: 0,
            invoiceId: "NEXUS",
            userId: userId,
            amount: topUpAmount,
            state: 5,
            superCell: null,
            code: null,
        }

        const orderId = await AddOrderBalance(data);
    };

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
        setPage(0); // Reset to first page
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 }); // Reset to the first page
    };



    const handleConfirm = async () => {
        setShowPopup(false);
        const userId = selectedUser
       
        // Send the data after image is resized and get the counts response
        try {
            await blockUser(userId)
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.userId === userId ? { ...user, isBlocked: !user.isBlocked } : user
                )
            );
        } catch (error) {
            console.error("Error sending message:", error);
        } finally {
        }


    };

    return (
        <>
            {showPopup && (
                <ConfirmPopup
                    isOpen={showPopup}
                    onConfirm={handleConfirm}
                    onCancel={() => setShowPopup(false)}
                    question={"Вы уверены?"}
                />
            )}
            <Box p={3}>
                <Box mb={4}>


                    <PopupMessage isOpen={isPopupMessageOpen} onClose={closeMessagePopup} userId={selectedUser} type={msgType} />
                    <Typography variant="h6">Пользователи</Typography>
                    <Box display="flex" alignItems="center" gap={2} mb={2}>
                        <Button variant="contained" onClick={handleAllMasage} sx={{ mb: 1 }}>
                            <EditIcon /> Написать всем
                        </Button>
                        <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Поиск по имени, username или коду"

                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </Box>
                    <DataGrid
                        rows={users}
                        density="compact"
                        getRowId={(row) => row.userId} // Use `userId` as the unique identifier
                        hideFooterSelectedRowCount={true}
                        columnHeaderHeight={40} disableColumnSelector

                        rowCount={totalCount}
                        sx={{
                            "& .MuiDataGrid-cell:focus": {
                                outline: "none",
                            },
                            "& .MuiDataGrid-columnHeader:focus": {
                                outline: "none",
                            },
                        }}
                        pagination
                        paginationMode="server"
                        pageSize={pageSize}
                        page={page}
                        paginationModel={paginationModel} // Controlled pagination model
                        onPaginationModelChange={handlePaginationChange} // Update pagination state
                        rowsPerPageOptions={[5, 10, 20]}
                        columns={[
                            { field: "userId", headerName: "Код", flex: 2 },
                            { field: "fullName", headerName: "Имя", flex: 2 },
                            {
                                field: "userName",
                                headerName: "Telegram",
                                flex: 3,
                                renderCell: (cellInfo) => {
                                    // Check if the userName is "no username"
                                    if (cellInfo.row.userName === "no username") {
                                        return (
                                            <p
                                                style={{
                                                    margin: 0,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    color: "red",
                                                }}
                                            >
                                                -
                                            </p>
                                        );
                                    }

                                    // Generate Telegram URL
                                    const telegramUrl = `https://t.me/${cellInfo.row.userName}`;

                                    // Render clickable Telegram link
                                    return (
                                        <a
                                            href={telegramUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                textDecoration: "none",
                                                display: "inline-flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <img
                                                src={TG} // Ensure `TG` is a valid image source or import
                                                alt="Telegram"
                                                style={{ width: "20px", height: "20px", marginRight: "5px" }}
                                            />
                                            <span style={{ color: "#1d9bf0", fontWeight: "bold" }}>
                                                {cellInfo.row.userName}
                                            </span>
                                        </a>
                                    );
                                },
                            },
                            {
                                field: "lastLoginDate",
                                headerName: "Дата последнего входа",
                                flex: 1,
                                renderCell: (cellInfo) => {
                                    return cellInfo.row.lastLoginDate ? formatDate(cellInfo.row.lastLoginDate) : "-";
                                }
                            },
                            {
                                field: "cDate",
                                headerName: "Дата регистрации",
                                flex: 1,
                                renderCell: (cellInfo) => {
                                    return cellInfo.row.cDate ? formatDate(cellInfo.row.cDate) : "-";
                                }
                            },
                            {
                                field: "actions",
                                headerName: "Действие",
                                flex: 3,
                                renderCell: (cellInfo) => (

                                    <Box display="flex" gap={1} sx={{
                                        "&:focus": {
                                            outline: "none",
                                        },
                                    }}>
                                        <Button
                                            variant="contained" sx={{
                                                "&:focus": {
                                                    outline: "none",
                                                },
                                            }}
                                            color="success"
                                            size="small"
                                            onClick={() => openPresentDialog(cellInfo.row.userId)}
                                        >
                                            Пополнить баланс
                                        </Button>

                                        <Button
                                            variant="contained" sx={{
                                                "&:focus": {
                                                    outline: "none",
                                                },
                                            }}
                                            color="info"
                                            size="small"
                                            onClick={() => handleMasage(cellInfo.row.userId)} // Access `row` instead of `data`
                                        >
                                            Написать
                                        </Button>

                                        <Button
                                            variant="contained" sx={{
                                                "&:focus": {
                                                    outline: "none",
                                                },
                                            }}
                                            color={cellInfo.row.isBlocked ? "warning": "error"}
                                            size="small"
                                            onClick={() => setShowPopup(true)} // Access `row` instead of `data`
                                        >
                                            {cellInfo.row.isBlocked ? "Разблокировать" : "Заблокировать"}
                                        </Button>
                                    </Box>
                                )
                            }


                        ]}
                        onRowClick={(params) => {
                            setSelectedUser(params.row.userId);
                        }}
                    />
                </Box>

                <Dialog open={presentDialogOpen} onClose={closePresentDialog}>
                    <Typography variant="h5" style={{ margin: "10px" }}>Пополнить Баланс</Typography>
                    <DialogContent>
                        <TextField
                            type="number"
                            label="Сумма пополнения"
                            value={presentAmount}
                            onChange={(e) => setPresentAmount(Number(e.target.value))}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closePresentDialog} color="secondary">
                            Отмена
                        </Button>
                        <Button onClick={confirmGivePresent} color="primary">
                            Подтвердить
                        </Button>
                    </DialogActions>
                </Dialog>

            </Box>
        </>
    );
};

export default Users;
