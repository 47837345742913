import React, { useState, useEffect } from "react";
import {
    getUserOrder, getUserPurchase, updateUserOrder, sendToTelegram, refundUserOrder
} from "../../api/apiServiceFetch";
import TG from "../../assets/tg.png";
import PopupMessage from "../../components/PopupMessage";
import { useAuth } from '../../contexts/auth';
import CurrencyConverter from '../../components/CurrencyConverter.js'
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    Paging,
    Toolbar,
    LoadPanel,
    Selection,
    Item as ToolbarItem
} from "devextreme-react/data-grid";
import { Button as DxButton } from "devextreme-react/button";
import Popup from "devextreme-react/popup";
import "devextreme/dist/css/dx.light.css";
import "./Orders.scss";

const filePath = process.env.REACT_APP_FILE_PATH;
const statusMapping = {
    0: "Ожидание",
    1: "Оплачено",
    2: "Отклонено",
    3: "СДЕЛАНО",
    4: "Ожидание",
    5: "Оплачено",
    6: "Отклонено",
};
const statusStyles = {
    0: { backgroundColor: "orange", color: "white", borderRadius: "5px" },
    1: { backgroundColor: "green", color: "white", borderRadius: "5px" },
    2: { backgroundColor: "red", color: "white", borderRadius: "5px" },
    3: { backgroundColor: "blue", color: "white", borderRadius: "5px" },
    4: { backgroundColor: "orange", color: "white", borderRadius: "5px" },
    5: { backgroundColor: "green", color: "white", borderRadius: "5px" },
    6: { backgroundColor: "red", color: "white", borderRadius: "5px" }
};

const formatDate = (dateString) => {
    if (!dateString) return "";

    const armenianDate = new Date(dateString);
    const moscowOffset = -1 * 60 * 60 * 1000;
    const moscowDate = new Date(armenianDate.getTime() + moscowOffset);

    return `${moscowDate.getFullYear()}/${String(moscowDate.getMonth() + 1).padStart(2, "0")
        }/${String(moscowDate.getDate()).padStart(2, "0")} ${String(moscowDate.getHours()).padStart(2, "0")
        }:${String(moscowDate.getMinutes()).padStart(2, "0")}`;
};

const OrdersUnified = () => {
    const [isCurrencyPopupOpen, setIsCurrencyPopupOpen] = useState(false);

    const openCurrencyPopup = () => {
        setIsCurrencyPopupOpen(true);
    };

    const closeCurrencyPopup = () => {
        setIsCurrencyPopupOpen(false);
    };
    const { userBase, signOut } = useAuth();
    const [isPopupMessageOpen, setIsPopupMessageOpen] = useState(false);
    const [orders, setOrders] = useState([]);
    const [loadingUserOrders, setLoadingUserOrders] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [userOrders, setUserOrders] = useState([]);
    const [selectedUserId, selSelectedUserId] = useState();

    // Refund dialog states
    const [refundDialogOpen, setRefundDialogOpen] = useState(false);
    const [refundAmount, setRefundAmount] = useState("");
    const [refundFull, setRefundFull] = useState(false);
    const [selectedRefundOrder, setSelectedRefundOrder] = useState(null); // хранит выбранный заказ для возврата

    const fetchOrders = async () => {
        try {
            const orderData = await getUserOrder();
            const isDataChanged = JSON.stringify(orderData) !== JSON.stringify(orders);
            if (isDataChanged) {
                setOrders(orderData);
            }
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    };

    const fetchUserOrders = async (orderId) => {
        setLoadingUserOrders(true);
        try {
            const userOrderData = await getUserPurchase(orderId);
            setUserOrders(userOrderData);
        } catch (error) {
            setUserOrders([]);
        } finally {
            setLoadingUserOrders(false);
        }
    };

    const openDialog = (action, row) => {
        setDialogAction(action);
        setSelectedRow(row);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
        setDialogAction(null);
        setSelectedRow(null);
    };

    const executeAction = async () => {
        try {
            if (dialogAction === "confirm") {
                await updateUserOrder({ orderId: selectedRow.orderId, state: 3 });
                await sendToTelegram({ chatId: selectedRow.userId, MessageKind: 1 });
            }
            fetchOrders();
        } catch (error) {
            console.error(`Error executing ${dialogAction} action:`, error);
        } finally {
            closeDialog();
        }
    };

    const openRefundDialog = (orderData) => {
        setSelectedRefundOrder(orderData);
        setRefundDialogOpen(true);
    };

    const closeRefundDialog = () => {
        setRefundDialogOpen(false);
        setRefundAmount("");
        setRefundFull(false);
        setSelectedRefundOrder(null);
    };

    const confirmRefund = async () => {
        if (!selectedRefundOrder) return;
        const maxAmount = selectedRefundOrder.amount;
        let amountToRefund = 0;

        if (refundFull) {
            amountToRefund = maxAmount;
        } else {
            amountToRefund = Number(refundAmount);
            if (isNaN(amountToRefund) || amountToRefund <= 0 || amountToRefund > maxAmount) {
                alert("Введите корректную сумму возврата (меньше или равную сумме заказа).");
                return;
            }
        }

        try {
            const state = await refundUserOrder({
                orderId: selectedRefundOrder.orderId,
                ReturnAll: refundFull,
                ReturnAmount: refundFull ? maxAmount : amountToRefund
            });

            if (state) {
                //await sendToTelegram({ chatId: selectedRefundOrder.userId, MessageKind: 2 });
                fetchOrders();
            }
            else {
                alert("Ошибка возврата средств.");
            }
        } catch (error) {
            console.error("Error processing refund:", error);
        } finally {
            closeRefundDialog();
        }
    };

    useEffect(() => {
        fetchOrders();
        const interval = setInterval(() => {
            fetchOrders();
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    const closeMessagePopup = () => {
        selSelectedUserId();
        setIsPopupMessageOpen(false);
    };

    const handleMasage = (userId) => {
        selSelectedUserId(userId);
        setIsPopupMessageOpen(true);
    };

    return (
        <div style={{ width: "100%" }}>
            <div style={{ marginBottom: "20px" }}>
                <DxButton
                    text="Открыть Конвертер Валют"
                    type="default"
                    icon="money"
                    onClick={openCurrencyPopup}
                />
            </div>

            {/* Popup for Currency Converter */}
            <Popup
                visible={isCurrencyPopupOpen}
                onHiding={closeCurrencyPopup}
                dragEnabled={false}
                showCloseButton={true}
                showTitle={true}
                title="Конвертер Валют"
                width={400}
            >
                <CurrencyConverter isOpen={isCurrencyPopupOpen} onClose={closeCurrencyPopup} />
            </Popup>
            <PopupMessage isOpen={isPopupMessageOpen} onClose={closeMessagePopup} userId={selectedUserId} type={1} />
            <h2>Управление заказами</h2>

            <div>
                <DataGrid
                    dataSource={orders}
                    keyExpr="orderId"
                    showBorders={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    paging={{ pageSize: 10 }}
                    onRowClick={(e) => fetchUserOrders(e.data.orderId)}
                    columnHidingEnabled={true}
                >
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <Toolbar>
                        <ToolbarItem location="after">
                            <DxButton hint="Обновить" icon="refresh" onClick={fetchOrders} />
                        </ToolbarItem>
                    </Toolbar>
                    <Selection mode="single" />
                    <Column dataField="orderId" caption="Код платежа" width={100} />
                    <Column
                        dataField="state"
                        caption="Статус"
                        width={120}
                        cellRender={(cellInfo) => (
                            <div
                                style={{
                                    ...statusStyles[cellInfo.data.state],
                                    padding: "5px",
                                    textAlign: "center",
                                }}
                            >
                                {statusMapping[cellInfo.data.state] || "Неизвестный статус"}
                            </div>
                        )}
                    />
                    <Column
                        width={100} dataField="userId" caption="Код покупателя" />
                    <Column
                        dataField="userName"
                        caption="Телеграм"
                        cellRender={(cellInfo) => {
                            if (cellInfo.data.userName === "no username") {
                                return <p style={{ margin: 0, color: "red" }}>-</p>;
                            }
                            const telegramUrl = `https://t.me/${cellInfo.data.userName}`;

                            return (
                                <a
                                    href={telegramUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: "none",
                                        display: "inline-flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <img
                                        src={TG}
                                        alt="Telegram"
                                        style={{ width: "20px", height: "20px", marginRight: "5px" }}
                                    />
                                    <span style={{ color: "#1d9bf0", fontWeight: "bold" }}>
                                        {cellInfo.data.userName}
                                    </span>
                                </a>
                            );
                        }}
                    />
                    <Column dataField="amount" caption="Сумма товаров" width={180} cellRender={(cellInfo) => {
                        const uah = Math.round(cellInfo.data.amount * cellInfo.data.exchangeRate);
                        return (
                            <div style={{ padding: "5px", color: "green" }}>
                                {`${cellInfo.data.amount} RUB`} <span style={{ color: "blue" }}>{`(${uah} ГРН)`}</span>
                            </div>
                        );
                    }} />
                    <Column dataField="payAmount" caption="Сумма к оплатe" width={180} cellRender={(cellInfo) => {
                        const uah = Math.round(cellInfo.data.payAmount * cellInfo.data.exchangeRate);
                        return (
                            <div style={{ padding: "5px" }}>
                                {cellInfo.data.payAmount > 0 ? `${cellInfo.data.payAmount} RUB (${uah} ГРН)` : 0}
                            </div>
                        );
                    }} />
                    <Column dataField="usedBalance" caption="Из баланса" width={180} cellRender={(cellInfo) => {
                        const uah = Math.round(cellInfo.data.usedBalance * cellInfo.data.exchangeRate);
                        return (
                            <div style={{ padding: "5px" }}>
                                {`${cellInfo.data.usedBalance} RUB (${uah} ГРН)`}
                            </div>
                        );
                    }} />
                    <Column dataField="superCell" caption="Почта для SUPERCELLID" width={220} cellRender={(cellInfo) => (
                        <div style={{ fontSize: "12px", padding: "5px" }}> {cellInfo.data.superCell}</div>
                    )} />
                    <Column dataField="code" caption="Код" width={80} />
                    <Column
                        dataField="cDate"
                        width={150}
                        caption="Дата добавления"
                        cellRender={(cellInfo) => formatDate(cellInfo.data.cDate)}
                    />
                    <Column
                        dataField="payDate"
                        width={150}
                        caption="Дата оплаты"
                        cellRender={(cellInfo) => formatDate(cellInfo.data.payDate)}
                    />
                    <Column
                        caption="Подтвердить"
                        width={130}
                        cellRender={(cellInfo) => (
                            <DxButton
                                text="Подтвердить"
                                type="success"
                                icon="check"
                                disabled={cellInfo.data.state !== 1}
                                onClick={() => openDialog("confirm", cellInfo.data)}
                            />
                        )}
                    />
                    <Column
                        caption="Написать"
                        width={130}
                        cellRender={(cellInfo) => (
                            <DxButton
                                text="Написать"
                                type="normal"
                                icon="email"
                                onClick={() => handleMasage(cellInfo.data.userId)}
                            />
                        )}
                    />
                    <Column
                        caption="Вернуть на баланс"
                        width={130}
                        disabled={!userBase.isSuperAdmin}
                        cellRender={(cellInfo) => (
                            <DxButton
                                text="Возврат"
                                type="default"
                                icon="undo"
                                disabled={cellInfo.data.state !== 1}
                                onClick={() => openRefundDialog(cellInfo.data)}
                            />
                        )}
                    />
                    <Paging defaultPageSize={10} />
                </DataGrid>

                {/* Popup for confirm/decline */}
                <Popup
                    visible={dialogOpen}
                    onHiding={closeDialog}
                    dragEnabled={false}
                    showCloseButton={true}
                    showTitle={true}
                    title={dialogAction === "confirm" ? "Подтвердить заказ" : "Отклонить заказ"}
                    width={350}
                    height={400}
                >
                    <div style={{ padding: "20px" }}>
                        <p>
                            Вы уверены, что хотите {dialogAction === "confirm" ? "подтвердить" : "отклонить"} заказ?
                        </p>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                            <DxButton
                                text="Отмена"
                                onClick={closeDialog}
                                style={{ marginRight: "10px" }}
                            />
                            <DxButton
                                text={dialogAction === "confirm" ? "Подтвердить" : "Отклонить"}
                                type="success"
                                onClick={executeAction}
                            />
                        </div>
                    </div>
                </Popup>

                {/* Popup for Refund */}
                <Popup
                    visible={refundDialogOpen}
                    onHiding={closeRefundDialog}
                    dragEnabled={false}
                    showCloseButton={true}
                    showTitle={true}
                    title="Возврат средств"
                    width={350}
                    height={250}
                >
                    <div style={{ padding: "20px", display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <label>
                            <input
                                type="checkbox"
                                checked={refundFull}
                                onChange={(e) => setRefundFull(e.target.checked)}
                            />
                            Полный возврат
                        </label>
                        {!refundFull && (
                            <input
                                type="number"
                                placeholder="Введите сумму возврата"
                                value={refundAmount}
                                onChange={(e) => setRefundAmount(e.target.value)}
                                style={{ width: '100%', padding: '5px' }}
                            />
                        )}
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                            <DxButton
                                text="Отмена"
                                onClick={closeRefundDialog}
                                style={{ marginRight: "10px" }}
                            />
                            <DxButton
                                text="Подтвердить"
                                type="success"
                                onClick={confirmRefund}
                            />
                        </div>
                    </div>
                </Popup>
            </div>

            {/* Secondary DataGrid */}
            {userOrders && userOrders.length > 0 &&
                <div style={{ marginTop: "30px", width: "100%" }}>
                    <h2>Детали заказа</h2>
                    <DataGrid
                        dataSource={userOrders}
                        keyExpr="id"
                        showBorders={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        height={300}
                    >
                        <LoadPanel enabled={loadingUserOrders} text="Загрузка..." />
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        <Column
                            dataField="parentName"
                            caption="Игра"
                            width={200}
                            cellRender={(cellInfo) => {
                                const imageUrl = `${filePath}/Games/${cellInfo.data.parentFileName}`;
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={imageUrl}
                                            alt=""
                                            style={{ width: '40px', height: '40px', marginRight: '8px', borderRadius: '5px' }}
                                        />
                                        <span>{cellInfo.data.parentName}</span>
                                    </div>
                                );
                            }}
                        />

                        <Column
                            dataField="itemName"
                            caption="Товар"
                            width={200}
                            cellRender={(cellInfo) => {
                                const imageUrl = `${filePath}/Games/${cellInfo.data.fileName}`;
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={imageUrl}
                                            alt=""
                                            style={{ width: '40px', height: '40px', marginRight: '8px', borderRadius: '5px' }}
                                        />
                                        <span>{cellInfo.data.itemName}</span>
                                    </div>
                                );
                            }}
                        />
                        <Column dataField="friendLink" caption="Ссылка в друзья" />
                        <Column dataField="count" caption="Количество" width={100} />
                        <Column dataField="price" caption="Цена" width={150} cellRender={(cellInfo) => {
                            const uah = Math.round(cellInfo.data.price * cellInfo.data.exchangeRate);
                            return (
                                <div style={{ padding: "8px" }}> {`${cellInfo.data.price} RUB (${uah} ГРН)`}</div>
                            );
                        }} />

                        <Paging defaultPageSize={10} />
                    </DataGrid>
                </div>}
        </div>
    );
};

export default OrdersUnified;
