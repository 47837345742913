import React, { createContext, useContext, useState } from 'react';

const LoadContext = createContext();

export const LoadProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    return (
        <LoadContext.Provider value={{ loading, setLoading }}>
            {children}
        </LoadContext.Provider>
    );
};

export const useLoad = () => {
    const context = useContext(LoadContext);
    if (context === undefined) {
        throw new Error('useLoad must be used within a LoadProvider');
    }
    return context;
};
