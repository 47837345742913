import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import './Header.scss';

const Header = ({ onMenuClick }) => {
  const { userBase, signOut } = useAuth();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    signOut();
    setDropdownVisible(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <button onClick={onMenuClick} className="menu-button">Меню</button>
      <div ref={dropdownRef} className="user" onClick={handleDropdownToggle}>
        <span>{userBase.fullName}</span>
        <div className={`logout ${dropdownVisible ? 'visible' : ''}`}>
          <button onClick={handleLogout}>Выйти</button>
        </div>
      </div>
    </header>
  );
};

export default Header;
