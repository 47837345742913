import React, { useState, useEffect } from "react";
import {
    getUserOrderArchive, getUserPurchase
} from "../../api/apiServiceFetch";

import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    Paging,
    Pager,
    Toolbar,
    LoadPanel,
    Selection,
    Item as ToolbarItem
} from "devextreme-react/data-grid";
import { Button as DxButton } from "devextreme-react/button";
import Popup from "devextreme-react/popup";
import "devextreme/dist/css/dx.light.css";

const filePath = process.env.REACT_APP_FILE_PATH;
const statusMapping = {
    0: "Ожидание",
    1: "Оплачено",
    2: "Отклонено",
    3: "СДЕЛАНО",
    4: "Ожидание",
    5: "Оплачено",
    6: "Отклонено",
};

const statusStyles = {
    0: { backgroundColor: "orange", color: "white", borderRadius: "5px" },
    1: { backgroundColor: "green", color: "white", borderRadius: "5px" },
    2: { backgroundColor: "red", color: "white", borderRadius: "5px" },
    3: { backgroundColor: "blue", color: "white", borderRadius: "5px" },
    4: { backgroundColor: "orange", color: "white", borderRadius: "5px" },
    5: { backgroundColor: "green", color: "white", borderRadius: "5px" },
    6: { backgroundColor: "red", color: "white", borderRadius: "5px" }
};

const formatDate = (dateString) => {
    if (!dateString) return "";

    // Parse the date string and adjust to Moscow time (UTC+3)
    const armenianDate = new Date(dateString); // Assuming the input is in UTC+4
    const moscowOffset = -1 * 60 * 60 * 1000; // UTC+3 is 1 hour behind UTC+4
    const moscowDate = new Date(armenianDate.getTime() + moscowOffset);

    return `${moscowDate.getFullYear()}/${String(moscowDate.getMonth() + 1).padStart(2, "0")
        }/${String(moscowDate.getDate()).padStart(2, "0")} ${String(moscowDate.getHours()).padStart(2, "0")
        }:${String(moscowDate.getMinutes()).padStart(2, "0")}`;
};

const Archive = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [userOrders, setUserOrders] = useState([]);
    const [loadingUserOrders, setLoadingUserOrders] = useState(false);

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const orderData = await getUserOrderArchive();
            setOrders(orderData);
        } catch (error) {
            console.error("Error fetching orders:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserOrders = async (orderId) => {
        setLoadingUserOrders(true);
        try {
            const userOrderData = await getUserPurchase(orderId);
            setUserOrders(userOrderData);
        } catch (error) {
            console.error("Error fetching user orders:", error);
        } finally {
            setLoadingUserOrders(false);
        }
    };


    useEffect(() => {
        fetchOrders();
    }, []);

    return (
        <div style={{ width: "100%" }}>
            <h2>Управление заказами</h2>
            {/* Primary DataGrid */}
            <div>
                <DataGrid
                    dataSource={orders}
                    keyExpr="orderId"
                    showBorders={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    paging={{ pageSize: 10 }}
                    onRowClick={(e) => fetchUserOrders(e.data.orderId)}
                    loading={loading}
                    columnChooser={true}
                    columnHidingEnabled={true}
                >
                    <LoadPanel enabled={loading} text="Загрузка..." />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <Toolbar>
                        <ToolbarItem location="after">
                            <DxButton hint="Обновить" icon="refresh" onClick={fetchOrders} />
                        </ToolbarItem>
                    </Toolbar>
                    <Selection mode="single" />
                    <Column dataField="orderId" caption="Код платежа" />
                    <Column
                        dataField="state"
                        caption="Статус"
                        cellRender={(cellInfo) => (
                            <div
                                style={{
                                    ...statusStyles[cellInfo.data.state],
                                    padding: "5px",
                                    textAlign: "center",
                                }}
                            >
                                {statusMapping[cellInfo.data.state] || "Неизвестный статус"}
                            </div>
                        )}
                    />
                    <Column dataField="userId" caption="Код покупателя" />
                    <Column
                        dataField="userName"
                        caption="Имя пользователя"
                        cellRender={(cellInfo) => (
                            <a
                                href={`https://t.me/${cellInfo.data.userName}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    textDecoration: "none",
                                    color: "#1d9bf0",
                                    fontWeight: "bold",
                                }}
                            >
                                {cellInfo.data.userName}
                            </a>
                        )}
                    />
                    <Column dataField="amount" caption="Сумма товаров" />
                    <Column dataField="payAmount" caption="Сумма к оплат" />
                    <Column dataField="usedBalance" caption="Из баланса" />
                    <Column dataField="superCell" caption="Почта для SUPERCELLID" />
                    <Column dataField="code" caption="Код для SUPERCELLID" />
                    <Column
                        dataField="payDate"
                        caption="Дата оплаты"
                        cellRender={(cellInfo) => formatDate(cellInfo.data.payDate)}
                    />
                    <Column
                        dataField="cDate"
                        caption="Дата добавления"
                        cellRender={(cellInfo) => formatDate(cellInfo.data.cDate)}
                    />
                    <Column
                        dataField="refDate"
                        caption="Дата пополнения"
                        cellRender={(cellInfo) => formatDate(cellInfo.data.refDate)}
                    />
                    <Column
                        dataField="mDate"
                        caption="Дата изменения"
                        cellRender={(cellInfo) => formatDate(cellInfo.data.mDate)}
                    />
                    <Paging defaultPageSize={10} />
                </DataGrid>
            </div>

            {/* Secondary DataGrid */}
            <div style={{ marginTop: "30px" }}>
                <h2>Детали заказа</h2>
                <DataGrid
                    dataSource={userOrders}
                    keyExpr="id"
                    showBorders={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    height={300}
                >
                    <LoadPanel enabled={loadingUserOrders} text="Загрузка..." />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <Column
                        dataField="parentName"
                        caption="Игра"
                        cellRender={(cellInfo) => {
                            const imageUrl = `${filePath}/Games/${cellInfo.data.parentFileName}`;
                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={imageUrl}
                                        alt=""
                                        style={{ width: '40px', height: '40px', marginRight: '8px', borderRadius: '5px' }}
                                    />
                                    <span>{cellInfo.data.parentName}</span>
                                </div>
                            );
                        }}
                    />

                    <Column
                        dataField="itemName"
                        caption="Товар"
                        cellRender={(cellInfo) => {
                            const imageUrl = `${filePath}/Games/${cellInfo.data.fileName}`;
                            return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={imageUrl}
                                        alt=""
                                        style={{ width: '40px', height: '40px', marginRight: '8px', borderRadius: '5px' }}
                                    />
                                    <span>{cellInfo.data.itemName}</span>
                                </div>
                            );
                        }}
                    />
                    <Column dataField="friendLink" caption="Ссылка в друзья" />
                    <Column dataField="count" caption="Количество" />
                    <Column dataField="price" caption="Цена" />


                    <Paging defaultPageSize={10} />
                </DataGrid>
            </div>
        </div>
    );
};

export default Archive;
