import React from 'react';
import './ConfirmPopup.scss';
const ConfirmPopup = ({ isOpen, onConfirm, onCancel, question }) => {
  if (!isOpen) return null;
  return (
    <div className="confirm-popup-overlay">
      <div className="confirm-popup">
        <p>{question}</p>
        <div className="confirm-popup-buttons">
          <button className="confirm-popup-button yes-button" onClick={onConfirm}>Да</button>
          <button className="confirm-popup-button no-button" onClick={onCancel}>Нет</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
