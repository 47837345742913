import React, { useRef, useEffect, useState } from 'react';
import './PopupMessage.scss'; // Ensure this file contains the custom styles
import ConfirmPopup from './ConfirmPopup';
import { sendToAllTelegram, sendToUserTelegram } from '../api/apiServiceFetch';
import resizeImage from './ResizeImage'; // Import the resizeImage function
import Loading from './Loading'; // Import the resizeImage function

const PopupMessage = ({ isOpen, onClose, userId, type }) => {
    const [message, setMessage] = useState('');
    const [image, setImage] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [counts, setCounts] = useState(null); // For storing success, failed, and deleted counts
    const textareaRef = useRef(null);
    
    const autoResizeTextarea = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        autoResizeTextarea();
    }, [message]);

    const handleConfirm = async () => {
        setShowPopup(false);
        setLoading(true); // Start loading

        let base64Image = null;
        if (image) {
            try {
                const wantedWidth = 800; // Set wanted width to 800 pixels
                const wantedHeight = 800; // Set wanted height to 800 pixels
                const { base64String } = await resizeImage(image, wantedWidth, wantedHeight);
                base64Image = base64String;
            } catch (error) {
                console.error("Error resizing image: ", error);
            }
        }

        const data = {
            userId: userId,
            message: message,
            base64Image: base64Image
        };

        // Send the data after image is resized and get the counts response
        try {
            if (type === 1) {
                await sendToUserTelegram(data)
            } else {
                await sendToAllTelegram(data);
            }

        } catch (error) {
            console.error("Error sending message:", error);
        }

        setLoading(false); // Stop loading
        setMessage('');
        setImage(null);
        onCloseHandle()
    };

    const handleCancel = () => {
        setShowPopup(false);
        setMessage('');
        setImage(null);
    };

    const handleSave = () => {
        setShowPopup(true);
    };

    const handleInputChange = (event) => {
        setMessage(event.target.value);
    };

    const handleImageChange = (event) => {
        setImage(event.target.files[0]);
    };

    const onCloseHandle = () => {
        setShowPopup(false);
        setCounts(null)
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="PopupMessage-overlay">
            <ConfirmPopup
                isOpen={showPopup}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                question={"Вы уверены?"}
            />
            <div className="PopupMessage-popup">
                <h2>Отправка сообщения</h2>

                {loading && <Loading />} {/* Loading bar displayed when sending messages */}

                {!loading && !counts && (
                    <>
                        <div>
                            <label htmlFor="message">
                                Напишите сообщение
                            </label>
                            <textarea
                                id="message"
                                className={'textArea'}
                                name="message"
                                value={message}
                                onChange={handleInputChange}
                                ref={textareaRef}
                            />
                        </div>
                        <div>
                            <label htmlFor="image">
                                Загрузить изображение
                            </label>
                            <input
                                type="file"
                                id="image"
                                name="image"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </div>
                        <div className="popup-buttons">
                            <button onClick={handleSave} className="save-button">Отправить</button>
                            <button onClick={onCloseHandle} className="cancel-button">Отмена</button>
                        </div>
                    </>
                )}

                {counts && (
                    <div className="popup-buttons">
                        <button onClick={onCloseHandle} className="close-button">Закрыть</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PopupMessage;