const API_BASE_URL = 'https://api.nexus-shop.ru/api';

const handleError = async (response) => {
    let errorMessage = 'Unknown error';
    if (response) {
        try {
            const errorData = await response.json();
            errorMessage = errorData.message || response.statusText;
        } catch (e) {
            errorMessage = response.statusText;
        }
    }
    console.error(`HTTP error: ${response.status} ${errorMessage}`);
    throw new Error(errorMessage);
};
const fetchApi = async (url, options = {}) => {
    let token = localStorage.getItem('token'); // Retrieve the token from local storage
    let headers = {
        'Content-Type': 'application/json',
        ...options.headers,
    };

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    try {
        let response = await fetch(`${API_BASE_URL}${url}`, {
            headers,
            ...options,
            credentials: 'include', // Ensures cookies or credentials are sent
        });

        if (response.status === 401) {
            // If unauthorized, try to refresh the token
            token = await refreshAccessToken();

            headers['Authorization'] = `Bearer ${token}`;
            response = await fetch(`${API_BASE_URL}${url}`, {
                headers,
                ...options,
                credentials: 'include', // Ensures cookies or credentials are sent
            });
        }

        if (!response.ok) {
            await handleError(response);
        }

        return response.json(); // Assume response is JSON
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};
const refreshAccessToken = async () => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        throw new Error('No refresh token available');
    }

    try {
        const response = await fetch(`${API_BASE_URL}/adminuser/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token, refreshToken }),
        });

        if (!response.ok) {
            throw new Error('Failed to refresh token');
        }

        const data = await response.json();
        localStorage.setItem('token', data.token); // Save new access token
        localStorage.setItem('refreshToken', data.refreshToken); // Save new refresh token

        return data.token;
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
    }
};

// User login
export const loginUser = async (loginDTO) => {
    try {
        const response = await fetchApi('/adminuser/login', {
            method: 'POST',
            body: JSON.stringify(loginDTO),
        });

        if (response.token) {
            localStorage.setItem('token', response.token); // Save the access token
            localStorage.setItem('refreshToken', response.refreshToken); // Save the refresh token
        }
        return response;
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};
export const getAdminUser = async () => {
    try {
        const response = await fetchApi('/adminuser/getAdminUser', {
            method: 'GET',
        });
        return response;
    } catch (error) {
        console.error('Get all users error:', error);
        throw error;
    }
};

export const getAllAdminUsers = async () => {
    try {
        const response = await fetchApi('/adminuser/all', {
            method: 'GET',
        });
        return response;
    } catch (error) {
        console.error('Get all users error:', error);
        throw error;
    }
};

export const getAllUsers = async (pageNumber = 1, pageSize = 10, searchQuery = "") => {
    try {
        let url = `/user/getUsersAll?pageNumber=${pageNumber}&pageSize=${pageSize}&searchQuery=${encodeURIComponent(searchQuery)}`
        const response = await fetchApi(url, {
            method: 'GET',
        });
        return response;
    } catch (error) {
        console.error('Get all users error:', error);
        throw error;
    }
};


export const blockUser = async (userId) => {
    try {
        const response = await fetchApi('/User/block', {
            method: 'POST',
            body: JSON.stringify(userId),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};
export const updateUser = async (data) => {
    try {
        const response = await fetchApi('/User/update', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};
export const getTBANKData = async () => {
    try {
        const url = `/moderation/getTBANKData`;
        const response = await fetchApi(url, {
            method: 'GET',
        });
        return response;
    } catch (error) {
        console.error('Check error:', error);
        throw error;
    }
};
export const getAllGames = async () => {
    try {
        let url = `/game/getAllGames`;
        const response = await fetchApi(url, {
            method: 'GET',
        });
        return response;
    } catch (error) {
        console.error('Get all users error:', error);
        throw error;
    }
};

export const getUserPurchase = async (orderId) => {
    try {
        let url = `/UserPurchase/getPurchasesByOrderId?orderId=${orderId}`;
        const response = await fetchApi(url, {
            method: 'GET',
        });
        return response;
    } catch (error) {
        console.error('Get all users error:', error);
        throw error;
    }
};
export const getPurchasesByUserId = async () => {
    try {
        let url = `/UserPurchase/getPurchasesByUserId`;
        const response = await fetchApi(url, {
            method: 'GET',
        });
        return response;
    } catch (error) {
        console.error('Get all users error:', error);
        throw error;
    }
};
export const getUserOrder = async () => {
    try {
        let url = `/UserOrder/getAllOrders`;
        const response = await fetchApi(url, {
            method: 'GET',
        });
        return response;
    } catch (error) {
        console.error('Get all users error:', error);
        throw error;
    }
};
export const getUserOrderArchive = async () => {
    try {
        let url = `/UserOrder/getAllOrdersArchive`;
        const response = await fetchApi(url, {
            method: 'GET',
        });
        return response;
    } catch (error) {
        console.error('Get all users error:', error);
        throw error;
    }
};
export const getUserOrderRefills = async () => {
    try {
        let url = `/UserOrder/getAllOrdersRefills`;
        const response = await fetchApi(url, {
            method: 'GET',
        });
        return response;
    } catch (error) {
        console.error('Get all users error:', error);
        throw error;
    }
};
export const updateUserOrder = async (data) => {
    try {
        const response = await fetchApi('/UserOrder/update', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};

export const convert = async (data) => {
    try {
        const response = await fetchApi('/UserOrder/convert', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};
export const refundUserOrder = async (data) => {
    try {
        const response = await fetchApi('/UserOrder/refund', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};
export const addGame = async (data) => {
    try {
        const response = await fetchApi('/game/add', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};

export const addItem = async (data) => {
    try {
        const response = await fetchApi('/item/add', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};

export const updateGame = async (data) => {
    try {
        const response = await fetchApi('/game/update', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};
export const updateItem = async (data) => {
    try {

        const response = await fetchApi('/item/update', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.log(data)
        console.error('Delete error:', error);
        throw error;
    }
};


export const deleteItem = async (data) => {
    try {
        const response = await fetchApi('/item/delete', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};


export const deleteGameGroup = async (data) => {
    try {
        const response = await fetchApi('/GameGroup/delete', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};

export const deleteGame = async (data) => {
    try {
        const response = await fetchApi('/Game/delete', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};
export const addGameGroup = async (data) => {
    try {
        const response = await fetchApi('/GameGroup/add', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};
export const updateGameGroup = async (data) => {
    try {
        const response = await fetchApi('/GameGroup/update', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};
export const deleteAdminUser = async (deleteDTO) => {
    try {
        const response = await fetchApi('/adminuser/delete', {
            method: 'POST',
            body: JSON.stringify(deleteDTO),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};
export const updateAdminUser = async (deleteDTO) => {
    try {
        const response = await fetchApi('/adminuser/update', {
            method: 'POST',
            body: JSON.stringify(deleteDTO),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};
export const registerAdminUser = async (deleteDTO) => {
    try {
        const response = await fetchApi('/adminuser/register', {
            method: 'POST',
            body: JSON.stringify(deleteDTO),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};

export const sendToTelegram = async (data) => {
    try {
        const response = await fetchApi('/telegram/send-message', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Massage send failure:', error);
        throw error;
    }
};
export const sendToAllTelegram = async (data) => {
    try {
        const response = await fetchApi('/telegram/sendToAll', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Massage send failure:', error);
        throw error;
    }
};

export const sendToUserTelegram = async (data) => {
    try {
        const response = await fetchApi('/telegram/sendToUser', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Massage send failure:', error);
        throw error;
    }
};



export const createOrder = async (createOrderDTO) => {
    try {
        const response = await fetchApi('/UserOrders/createGift', {
            method: 'POST',
            body: JSON.stringify(createOrderDTO),
        });
        return response;
    } catch (error) {
        console.error('Create error:', error);
        throw error;
    }
}
//getBanners, addBanner, editBanner, deleteBanner

export const getBanners = async () => {
    try {
        let url = `/Banner/getAdminBanners`;
        const response = await fetchApi(url, {
            method: 'GET',
        });
        return response;
    } catch (error) {
        console.error('Get all users error:', error);
        throw error;
    }
};

export const addBanner = async (data) => {
    try {
        const response = await fetchApi('/Banner/add', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};

export const editBanner = async (data) => {
    try {
        const response = await fetchApi('/Banner/update', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};

export const deleteBanner = async (data) => {
    try {
        const response = await fetchApi('/Banner/delete', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Delete error:', error);
        throw error;
    }
};

export const AddOrderBalance = async (data) => {
    try {
        const response = await fetchApi('/UserOrder/add', {
            method: 'POST',
            body: JSON.stringify(data),
        });
        return response;
    } catch (error) {
        console.error('Payment create failure:', error);
        throw error;
    }
};