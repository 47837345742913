import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAdminUser } from '../api/apiServiceFetch';


function AuthProvider(props) {
  const navigate = useNavigate();
  const [userBase, setUserBase] = useState();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    (async function () {
      try {
        const user = await getAdminUser();
        setUserBase(user);

      } catch (error) {
        console.error('Failed to load user:', error);
        navigate('/login');
      } finally {
        setLoading(false);
      }
    })();
  }, [navigate]);

  const refreshUser = useCallback(async (userId) => {
  }, []);

  const signOut = useCallback(() => {
    setUserBase(null);
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('token');
    navigate('/login');
  }, [navigate]);

  const onClose = () => {
  }
  return (
    <AuthContext.Provider value={{ userBase, setUserBase, signOut, onClose, refreshUser, loading }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
