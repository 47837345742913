import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import routes from './routes'; // Ensure this path is correct
import Layout from './components/Layout'; // Ensure this path is correct

const Content = () => {
  const pageTransition = {
    initial: { opacity: 0, y: 20, height: '100vh', width: '100%' },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
    transition: { duration: 0.3 }
  };

  return (
    <Routes>
      {routes.map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          element={
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={pageTransition}
            >
              <Layout>
                {element}
              </Layout>
            </motion.div>
          }
        />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Content;
