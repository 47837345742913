const resizeImage = (file, wantedWidth, wantedHeight) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > wantedWidth) {
                    height = Math.round((height *= wantedWidth / width));
                    width = wantedWidth;
                }
            } else {
                if (height > wantedHeight) {
                    width = Math.round((width *= wantedHeight / height));
                    height = wantedHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
                (blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64String = reader.result.split(',')[1];
                        const fileExtension = 'jpg'; // Always set to jpg
                        resolve({ base64String, fileName: `${file.name.split('.')[0]}.jpg`, fileExtension });
                    };
                    reader.readAsDataURL(blob);
                },
                'image/jpeg',
                0.90
            );
        };
        img.onerror = (err) => reject(err);
    });
};

export default resizeImage;
