import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../api/apiServiceFetch';
import BrawlStars from '../../assets/BrawlStars.png';
import ClashOfClans from '../../assets/ClashOfClans.png';
import ClashRoyal from '../../assets/ClashRoyal.png';
import SquadBusters from '../../assets/SquadBusters.png';
import Character from '../../assets/character.avif';
import './Login.scss';

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const [isUsernameEmpty, setIsUsernameEmpty] = useState(false);
    const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the fields are empty
        const isUsernameEmptyCheck = formData.username.trim() === '';
        const isPasswordEmptyCheck = formData.password.trim() === '';

        setIsUsernameEmpty(isUsernameEmptyCheck);
        setIsPasswordEmpty(isPasswordEmptyCheck);

        if (!isUsernameEmptyCheck && !isPasswordEmptyCheck) {
            try {
                const loginResponse = await loginUser(formData);
                if (loginResponse.isSuccess) {
                    navigate('/orders');
                } else {
                    setErrorMessage('Неправильные данные');
                }
            } catch (error) {
                setErrorMessage('Ошибка логина');
            }
        }
    };

    return (
        <div className="container">

            <img src={Character} alt="character" className="character" />
            <div className="login-container">
                <div className='login_header'>
                    <img src={BrawlStars} alt="Logo" className="logo" />
                    <img src={ClashOfClans} alt="Logo" className="logo" />
                    <img src={ClashRoyal} alt="Logo" className="logo" />
                    <img src={SquadBusters} alt="Logo" className="logo" />
                </div>
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className={`form-group ${isUsernameEmpty ? 'reqLabel' : ''}`}>
                        <label htmlFor="username" className={`${isUsernameEmpty ? 'reqLabel' : ''}`}>Логин{isUsernameEmpty ? ' *' : ''}</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            placeholder={'Введите ваш логин'}
                            onChange={handleInputChange}
                            onFocus={() => setIsUsernameEmpty(false)}
                            className={isUsernameEmpty ? 'reqInput' : ''}
                        />
                    </div>
                    <div className={`form-group ${isPasswordEmpty ? 'reqLabel' : ''}`}>
                        <label htmlFor="password" className={`${isPasswordEmpty ? 'reqLabel' : ''}`}>Пароль{isPasswordEmpty ? ' *' : ''}</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            placeholder={'Введите ваш пароль'}
                            onChange={handleInputChange}
                            onFocus={() => setIsPasswordEmpty(false)}
                            className={isPasswordEmpty ? 'reqInput' : ''}
                        />
                    </div>
                    {/* <div className="form-group center">
                        <label htmlFor="captcha">{t('enterTheAnswer')}</label>
                        <div className='captcha'>
                            <label htmlFor="captcha">{captcha.question}</label>
                            <input
                                type="text"
                                id="captcha"
                                name="captcha"
                                autoComplete="off"
                                value={formData.captcha}
                                placeholder="XX"
                                onChange={handleNumericInputChange} // Use numeric input handler
                                maxLength={2}
                                className={isCaptchaIncorrect ? 'reqInput' : ''}
                            />
                        </div>
                        {isCaptchaIncorrect && <span className='reqLabel'>{t('incorrectAnswer')}</span>}
                    </div> */}
                    {errorMessage && <div className='error-message'>{errorMessage}</div>}
                    <div className='btn-div'><button type="submit">Войти</button></div>
                </form>
            </div>
        </div>
    );
};

export default Login;