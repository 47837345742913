
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/auth';
import { NavigationProvider } from './contexts/navigation';
import { AnimatePresence } from 'framer-motion';
import Content from './Content';
import Loading from './components/Loading';
import './App.scss';
import UnauthenticatedContent from './UnauthenticatedContent';
import { LoadProvider } from './contexts/LoadContext';

function App() {
  // Use the custom hook to get the authentication state and loading status
  const { userBase, loading } = useAuth();

  // If the app is still loading (e.g., checking auth status), show a loading screen
  if (loading) {
    return <Loading text={true} />;
  }

  // // If the user is authenticated, show the main content
  if (userBase) {
    return <Content />;
  }

  // If the user is not authenticated, show the unauthenticated content (e.g., login page)
  return <UnauthenticatedContent />;
}

export default function Root() {
  return (
    <AnimatePresence mode="wait">
      <Router basename="/">
        <AuthProvider>
          <LoadProvider>
            <NavigationProvider>
              <div className="app">
                <App />
              </div>
            </NavigationProvider>
          </LoadProvider>
        </AuthProvider>
      </Router>
    </AnimatePresence>
  );
}
