import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SideMenu.scss';
import { useAuth } from '../../contexts/auth';

const SideMenu = ({ isMenuOpen, setIsMenuOpen }) => {
    const navigate = useNavigate();

    const { userBase, signOut } = useAuth();

    const handleNavigation = (path) => {
        navigate(path);
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className={`side-menu ${isMenuOpen ? 'open' : ''}`}>
            <ul>
                <li onClick={() => handleNavigation('/')}>Заказы</li>
                <li onClick={() => handleNavigation('/refills')}>Пополнения</li>
                <li onClick={() => handleNavigation('/archive')}>Архив</li>
                {userBase.isSuperAdmin && <li onClick={() => handleNavigation('/profiles')}>Клиенты</li>}
                {userBase.isSuperAdmin && <li onClick={() => handleNavigation('/users')}>Юзеры</li>}
                <li onClick={() => handleNavigation('/games')}>Игры</li>
                <li onClick={() => handleNavigation('/banners')}>Баннеры</li>
            </ul>
        </div>
    );
};

export default SideMenu;
