import React, { useState } from 'react';
import Header from './header/Header';
import SideMenu from './sideMenu/SideMenu';
import Footer from './footer/Footer';
import './Layout.scss';

const Layout = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="layout">
            <Header onMenuClick={toggleMenu} />
            <SideMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            <div className={`content ${isMenuOpen ? 'menu-open' : ''}`}>
                {children}
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
