import React, { useState, useEffect } from 'react';
import { convert } from '../api/apiServiceFetch';

const CurrencyConverter = ({ isOpen, onClose }) => {
    const [amount, setAmount] = useState('');
    const [sourceCurrency, setSourceCurrency] = useState('UAH');
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [rubPercentInput, setRubPercentInput] = useState(localStorage.getItem("currPercent") || 0); // Введённый процент для RUB
    const [rubWithPercent, setRubWithPercent] = useState(0); // Итоговая сумма RUB с процентом

    useEffect(() => {
        if (!isOpen) {
            resetState();
        }
    }, [isOpen]);

    const resetState = () => {
        setAmount('');
        setSourceCurrency('UAH');
        setResult(null);
        setError(null);
        setRubWithPercent(0);
    };

    const handleConvert = async () => {
        setError(null);
        setResult(null);

        if (!amount || isNaN(amount) || amount <= 0) {
            setError('Введите корректную сумму.');
            return;
        }

        try {
            const data = { amount: parseFloat(amount), sourceCurrency };
            const response = await convert(data);
            setResult(response);

            // Если результат есть, пересчитать RUB с процентом
            if (response?.amountInRUB) {
                calculateRubWithPercent(response.amountInRUB, rubPercentInput);
            }
        } catch (err) {
            setError('Не удалось выполнить конвертацию валюты. Попробуйте еще раз.');
        }
    };

    const calculateRubWithPercent = (baseRub, percent) => {
        const calculatedRub = baseRub + (baseRub * (percent / 100));
        setRubWithPercent(calculatedRub);
    };

    const handlePercentChange = (percent) => {
        if (result?.amountInRUB) {
            calculateRubWithPercent(result.amountInRUB, percent);
        }
        setRubPercentInput(percent);
        localStorage.setItem("currPercent", percent);
    };

    return (
        <div style={{ maxWidth: '400px', margin: '20px auto', padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
            <div>
                <label>
                    Сумма:
                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        style={{ margin: '10px', padding: '8px', width: '100%' }}
                        placeholder="Введите сумму"
                    />
                </label>
            </div>
            <div>
                <label>
                    Валюта:
                    <select
                        value={sourceCurrency}
                        onChange={(e) => setSourceCurrency(e.target.value)}
                        style={{ margin: '10px', padding: '8px', width: '100%' }}
                    >
                        <option value="UAH">Гривны</option>
                        <option value="KZT">Тенге</option>
                        <option value="RUB">Рубли</option>
                    </select>
                </label>
            </div>
            <div>
                <label>
                    Процент для RUB:
                    <input
                        type="number"
                        value={rubPercentInput}
                        onChange={(e) => handlePercentChange(Number(e.target.value))}
                        style={{ margin: '10px', padding: '8px', width: '100%' }}
                        placeholder="Введите процент для RUB"
                    />
                </label>
            </div>
            <button
                onClick={handleConvert}
                style={{
                    marginTop: '10px',
                    padding: '10px 20px',
                    backgroundColor: '#007BFF',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                }}
            >
                Конвертировать
            </button>
            <button
                onClick={onClose}
                style={{
                    marginTop: '10px',
                    marginLeft: '10px',
                    padding: '10px 20px',
                    backgroundColor: '#DC3545',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                }}
            >
                Закрыть
            </button>
            {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
            {result && (
                <div style={{ marginTop: '20px' }}>
                    <h4>Результат конвертации:</h4>
                    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Валюта</th>
                                <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Сумма</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>UAH (Гривны)</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{result.amountInUAH?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>KZT (Тенге)</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{result.amountInKZT?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>RUB (Рубли)</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{result.amountInRUB?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px', color: 'green' }}>RUB + Проценты (Рубли)</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px', color: 'green' }}>{rubWithPercent?.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default CurrencyConverter;
