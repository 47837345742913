import React from 'react';
import './Loading.scss';

const Loading = ({ text }) => {

    return (
        <div className="loading-overlay">
        </div>
    );
};

export default Loading;
