import React, { useState, useEffect } from "react";

import { getBanners, addBanner, editBanner, deleteBanner } from "../../api/apiServiceFetch";
import { DataGrid } from "@mui/x-data-grid";
import {
    Button,
    TextField,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Select,
    FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add"; // Correct import
import EditIcon from "@mui/icons-material/Edit"; // Correct import
import DeleteIcon from "@mui/icons-material/Delete";
import "./Banners.scss";

const Banners = () => {
    const filePath = process.env.REACT_APP_FILE_PATH;
    const [banners, setBanners] = useState([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState("");
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [formData, setFormData] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState("");

    const fetchAllBanners = async () => {
        try {
            const bannerData = await getBanners();
            setBanners(bannerData);
        } catch (error) {
            console.error("Error fetching games:", error);
        }
    };

    useEffect(() => {
        fetchAllBanners();
    }, []);

    const openDialog = (data = {}, isEdit = false) => {
        setFormData({
            ...data,
            sort: data.sort || 0,
            isActive: data.isActive || false,
        });
        setEditMode(isEdit);
        setDialogOpen(true);
        setError("");
    };

    const closeDialog = () => {
        setDialogOpen(false);
        setError("");
        setEditMode(false);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };



    const saveData = async () => {
        try {
            if (!formData.name || !formData.description || !formData.fileName && !editMode) {
                setError("Все поля обязательны.");
                return;
            }
            try {
                if (editMode) {
                    // Update existing game via API
                    const newBanner = {
                        id: formData.id, // Use existing ID for editing
                        name: formData.name,
                        description: formData.description,
                        fileName: formData.fileName,
                        link1: formData.link1,
                        linkButton1: formData.linkButton1,
                        link2: formData.link2,
                        linkButton2: formData.linkButton2,
                        sort: formData.sort,
                        isActive: formData.isActive,
                        photoBase64: formData.base64String
                    };
                    await editBanner(newBanner);
                } else {
                    const newBanner = {
                        name: formData.name,
                        description: formData.description,
                        fileName: formData.fileName,
                        link1: formData.link1,
                        linkButton1: formData.linkButton1,
                        link2: formData.link2,
                        linkButton2: formData.linkButton2,
                        sort: formData.sort,
                        isActive: formData.isActive,
                        photoBase64: formData.base64String
                    };

                    await addBanner(newBanner);
                }

                closeDialog();
            } catch (apiError) {
                console.error("Error updating/adding game via API:", apiError);
                setError(editMode ? "Ошибка при обновлении." : "Ошибка при добавлении.");
            }


            closeDialog();
        } catch (error) {
            console.error("Unexpected error:", error);
            setError("Произошла ошибка.");
        } finally {
            fetchAllBanners();
        }
    };


    const deleteRecord = (id) => {
        setBanners(banners.filter((g) => g.id !== id));
        const data = {
            id: id
        }
        deleteBanner(data);
    };

    return (
        <Box p={3}>
            <Box mb={4}>
                <Typography variant="h6">Баннеры</Typography>
                <Button variant="contained" onClick={() => openDialog()} sx={{ mb: 1 }}>
                    <AddIcon />
                </Button>
                <DataGrid
                    rows={banners}
                    density="compact"
                    hideFooterSelectedRowCount={true}
                    columnHeaderHeight={40} disableColumnSelector
                    rowHeight={50}
                    columns={[
                        {
                            field: "fileName",
                            headerName: "Баннер",
                            flex: 1,
                            renderCell: (params) => (
                                <img src={`${filePath}/Banners/${params.row.fileName}`} alt="banner" width="auto" height="40" />

                            ),
                        },
                        { field: "name", headerName: "Название", flex: 2 },
                        { field: "description", headerName: "Описание", flex: 3 },
                        { field: "link1", headerName: "Гиперссылка 1", flex: 1 },
                        { field: "linkButton1", headerName: "Текст Кнопки 1", flex: 1 },
                        { field: "link2", headerName: "Гиперссылка 2", flex: 1 },
                        { field: "linkButton2", headerName: "Текст Кнопки 2", flex: 1 },
                        { field: "sort", headerName: "Сортировка", flex: 1 },
                        {
                            field: "isActive",
                            headerName: "Активно",
                            flex: 1,
                            renderCell: (params) =>
                                params.row.isActive ? "Да" : "Нет",
                        },
                        {
                            field: "actions",
                            headerName: "Действия",
                            flex: 2,
                            renderCell: (params) => (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <Button
                                        color="warning"
                                        onClick={() => openDialog(params.row, true)}
                                    >
                                        <EditIcon />
                                    </Button>
                                    <Button
                                        color="error"
                                        onClick={() => deleteRecord(params.row.id)}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </Box>
                            ),
                        },
                    ]}
                    pageSize={5}
                    onRowClick={(params) => {
                        setSelectedBanner(params.row.id);
                    }}
                />
            </Box>
            <Dialog open={dialogOpen} onClose={closeDialog}>
                <DialogTitle>
                    {editMode
                        ? `Редактировать баннер`
                        : `Добавить баннер`}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="name"
                        label="Название"
                        value={formData.name || ""}
                        onChange={handleInputChange}
                        required
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        name="description"
                        label="Описание"
                        height="auto"
                        value={formData.description || ""}
                        onChange={handleInputChange}
                        required
                        multiline // Enables multi-line input
                        rows={4} // Adjust height
                        variant="outlined"
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        name="link1"
                        label="Кнопка 1"
                        value={formData.link1 || ""}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        name="linkButton1"
                        label="Текст Кнопки 1"
                        value={formData.linkButton1 || ""}
                        onChange={handleInputChange}
                        required
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        name="link2"
                        label="Кнопка 2"
                        value={formData.link2 || ""}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        name="linkButton2"
                        label="Текст Кнопки 2"
                        value={formData.linkButton2 || ""}
                        onChange={handleInputChange}
                        required
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        name="sort"
                        label="Сортировка"
                        type="number" // Makes the input field accept only numbers
                        value={formData.sort || 0}
                        onChange={handleInputChange}
                        required
                        sx={{
                            marginTop: "10px", // Optional additional margin
                        }}
                    />


                    <Box>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (!file) return;
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        fileName: file.name,
                                        base64String: reader.result.split(",")[1],
                                    }));
                                };
                                reader.readAsDataURL(file);
                            }}
                            id="upload-button-fileName"
                            style={{ display: "none" }} // Hides the default file input
                        />
                        <label htmlFor="upload-button-fileName">
                            <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                sx={{
                                    margin: "10px 0",
                                    padding: "10px 20px",
                                    fontSize: "14px",
                                }}
                            >
                                Загрузить изображение
                            </Button>
                        </label>
                        <Box sx={{ marginTop: "10px", textAlign: "center" }}>
                            <Typography variant="subtitle1">Предпросмотр</Typography>
                            <img
                                src={
                                    formData.base64String
                                        ? `data:image/jpeg;base64,${formData.base64String}`
                                        : `${filePath}/Banners/${formData.fileName}`
                                }
                                alt="Preview"
                                style={{
                                    width: "auto",
                                    height: "100px",
                                    objectFit: "cover",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                }}
                            />
                        </Box>
                    </Box>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.isActive || false}
                                onChange={handleInputChange}
                                name="isActive"
                            />
                        }
                        label="Активно"
                    />
                </DialogContent>
                {error && (
                    <Typography color="error" sx={{ pl: 2 }}>
                        {error}
                    </Typography>
                )}<DialogActions
                    sx={{
                        justifyContent: "flex-end", // Adjust button alignment
                        padding: "16px", // Add padding for spacing
                        backgroundColor: "#f5f5f5", // Optional background color for the footer
                    }}
                >
                    <Button
                        onClick={closeDialog}
                        sx={{
                            backgroundColor: "#f44336", // Red background
                            color: "#fff", // White text
                            fontWeight: "bold", // Bold text
                            padding: "8px 16px", // Adjust padding
                            borderRadius: "5px", // Rounded corners
                            "&:hover": {
                                backgroundColor: "#d32f2f", // Darker red on hover
                            },
                        }}
                    >
                        Отменить
                    </Button>
                    <Button
                        onClick={saveData}
                        sx={{
                            backgroundColor: "#4caf50", // Green background
                            color: "#fff", // White text
                            fontWeight: "bold", // Bold text
                            padding: "8px 16px", // Adjust padding
                            borderRadius: "5px", // Rounded corners
                            "&:hover": {
                                backgroundColor: "#388e3c", // Darker green on hover
                            },
                        }}
                    >
                        {editMode ? "Обновить" : "Сохранить"}
                    </Button>
                </DialogActions>

            </Dialog>
        </Box>
    );
};

export default Banners;
