import React, { useState, useEffect } from "react";
import {
    getAllGames, addGame, updateGame, deleteGame,
    addGameGroup, updateGameGroup, deleteGameGroup,
    addItem, updateItem, deleteItem,
    loginWithSupercell
} from "../../api/apiServiceFetch";
import { DataGrid } from "@mui/x-data-grid";
import {
    Button,
    TextField,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Select,
    FormControl,
} from "@mui/material";
import CurrencyConverter from '../../components/CurrencyConverter.js'
import Popup from "devextreme-react/popup";
import { Button as DxButton } from "devextreme-react/button";
import AddIcon from "@mui/icons-material/Add"; // Correct import
import EditIcon from "@mui/icons-material/Edit"; // Correct import
import DeleteIcon from "@mui/icons-material/Delete";
import "./Home.scss";
import { form } from "framer-motion/m";

const Home = () => {
    const filePath = process.env.REACT_APP_FILE_PATH;

    const [isCurrencyPopupOpen, setIsCurrencyPopupOpen] = useState(false);

    const openCurrencyPopup = () => {
        setIsCurrencyPopupOpen(true);
    };

    const closeCurrencyPopup = () => {
        setIsCurrencyPopupOpen(false);
    };
    const [games, setGames] = useState([]);
    const [groups, setGroups] = useState([]);
    const [items, setItems] = useState([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState(""); // 'game', 'group', or 'item'
    const [selectedGame, setSelectedGame] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [formData, setFormData] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState("");

    const fetchAllGames = async () => {
        try {
            const gameData = await getAllGames();

            const processedGames = gameData.map((game) => ({
                id: game.gameId,
                ...game,
            }));

            const processedGroups = gameData.flatMap((game) =>
                game.gameGroups.map((group) => ({
                    id: group.groupId,
                    gameId: game.gameId,
                    ...group,
                }))
            );

            const processedItems = gameData.flatMap((game) => [
                ...game.itemsWithoutGroup.map((item) => ({
                    id: item.itemId,
                    gameId: game.gameId,
                    groupId: null,
                    ...item,
                })),
                ...game.gameGroups.flatMap((group) =>
                    group.items.map((item) => ({
                        id: item.itemId,
                        gameId: game.gameId,
                        groupId: group.groupId,
                        ...item,
                    }))
                ),
            ]);

            setGames(processedGames);
            setGroups(processedGroups);
            setItems(processedItems);
        } catch (error) {
            console.error("Error fetching games:", error);
        }

        // const data = {
        //     email: "arturvard92@gmail.com",
        //     game: "laser"
        // }
        // await loginWithSupercell(data)
        //     .then((data) => {
        //         console.log('Login Successful:', data);
        //     })
        //     .catch((error) => {
        //         console.error('Login Failed:', error.message);
        //     });
    };
    const gameTypes = [
        { label: "Hay Day", value: "soil" },
        { label: "Clash of Clans", value: "magic" },
        { label: "Boom Beach", value: "reef" },
        { label: "Clash Royale", value: "scroll" },
        { label: "Brawl Stars", value: "laser" },
    ];
    useEffect(() => {
        fetchAllGames();
    }, []);

    const openDialog = (type, data = {}, isEdit = false) => {
        setDialogType(type);
        setFormData({
            ...data,
            sort: data.sort || 0,
            isActive: data.isActive || false,
        });
        setEditMode(isEdit);
        setDialogOpen(true);
        setError("");
    };

    const closeDialog = () => {
        setDialogOpen(false);
        setError("");
        setEditMode(false);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };


    const handleFileUpload = (e, fieldName = "base64String") => {
        const file = e.target.files[0]; // Get the selected file

        if (!file) {
            setError("Выберите файл."); // Set an error if no file is selected
            return;
        }

        const reader = new FileReader();

        reader.onloadend = () => {
            try {
                const base64Data = reader.result.split(",")[1]; // Extract Base64 string
                setFormData((prev) => ({
                    ...prev,
                    [fieldName]: base64Data, // Dynamically set base64String or mainBase64String
                    file, // Save the original file object
                }));
                setError(""); // Clear any previous errors
            } catch (error) {
                console.error("Error processing file:", error);
                setError("Ошибка обработки изображения.");
            }
        };

        reader.onerror = (error) => {
            console.error("Error reading file:", error);
            setError("Ошибка чтения файла.");
        };

        reader.readAsDataURL(file); // Convert the file to Base64 format
    };



    const saveData = async () => {
        try {
            if (dialogType === "game") {
                if (!formData.name || !formData.description || !formData.fileName && !editMode) {
                    setError("Все поля для Игры обязательны.");
                    return;
                }
                try {
                    if (editMode) {
                        // Update existing game via API
                        const newGame = {
                            gameId: formData.gameId, // Use existing ID for editing
                            name: formData.name,
                            description: formData.description,
                            mainFileName: formData.mainFileName,
                            fileName: formData.fileName,
                            gameType: formData.gameType,
                            sort: formData.sort,
                            isActive: formData.isActive,
                            haveSuperCell: formData.haveSuperCell,
                            cDate: new Date().toISOString(),
                            photoBase64: formData.base64String,
                            mainPhotoBase64: formData.mainBase64String,
                        };
                        await updateGame(newGame);
                    } else {
                        const newGame = {
                            gameId: editMode ? formData.gameId : 0, // Use existing ID for editing
                            name: formData.name,
                            description: formData.description,
                            mainFileName: formData.mainFileName,
                            fileName: formData.fileName,
                            gameType: formData.gameType,
                            sort: formData.sort,
                            isActive: formData.isActive,
                            haveSuperCell: formData.haveSuperCell,
                            cDate: new Date().toISOString(),
                            photoBase64: formData.base64String,
                            mainPhotoBase64: formData.mainBase64String,
                        };

                        await addGame(newGame);
                    }

                    closeDialog();
                } catch (apiError) {
                    console.error("Error updating/adding game via API:", apiError);
                    setError(editMode ? "Ошибка при обновлении игры." : "Ошибка при добавлении игры.");
                }
            } else if (dialogType === "group") {
                if (!formData.gameId) {
                    setError("Сперва выберите игру");
                    return;
                }
                if (!formData.name) {
                    setError("Все поля для Группы обязательны.");
                    return;
                }

                if (editMode) {
                    const newGameGroup = {
                        groupId: formData.groupId,
                        gameId: formData.gameId,
                        name: formData.name,
                        isActive: formData.isActive,
                        sort: Number(formData.sort)
                    };
                    await updateGameGroup(newGameGroup);

                } else {

                    const newGameGroup = {
                        gameId: formData.gameId,
                        name: formData.name,
                        isActive: formData.isActive,
                        sort: Number(formData.sort)
                    };
                    await addGameGroup(newGameGroup);

                }
            } else if (dialogType === "item") {
                if (!formData.name || !formData.price || !formData.fileName && !editMode) {
                    setError("Все поля для Товара обязательны.");
                    return;
                }

                if (editMode) {
                    const newItem = {
                        itemId: formData.itemId,
                        gameId: formData.gameId,
                        groupId: formData.groupId,
                        fileName: formData.fileName,
                        name: formData.name,
                        description: formData.description,
                        isNew: formData.isNew,
                        isPopular: formData.isPopular,
                        isLimited: formData.isLimited,
                        isPromotion: formData.isPromotion,
                        promotionPeriod: formData.promotionPeriod,
                        noLogin: formData.noLogin,
                        price: formData.price,
                        discount: formData.discount,
                        sort: Number(formData.sort),
                        isActive: formData.isActive,
                        cDate: new Date().toISOString(),
                        photoBase64: formData.base64String,
                    };
                    await updateItem(newItem)
                } else {

                    const newItem = {
                        itemId: 0,
                        gameId: formData.gameId,
                        groupId: formData.groupId,
                        fileName: formData.fileName,
                        name: formData.name,
                        description: formData.description,
                        isNew: formData.isNew,
                        isPopular: formData.isPopular,
                        isLimited: formData.isLimited,
                        isPromotion: formData.isPromotion,
                        promotionPeriod: formData.promotionPeriod,
                        noLogin: formData.noLogin,
                        price: formData.price,
                        discount: formData.discount,
                        sort: Number(formData.sort),
                        isActive: formData.isActive,
                        cDate: new Date().toISOString(),
                        photoBase64: formData.base64String,
                    };
                    await addItem(newItem)
                }
            }

            closeDialog();
        } catch (error) {
            console.error("Unexpected error:", error);
            setError("Произошла ошибка.");
        } finally {
            fetchAllGames();
        }
    };


    const deleteRecord = (id, type) => {
        if (type === "game") {
            setGames(games.filter((g) => g.id !== id));
            setGroups(groups.filter((group) => group.gameId !== id));
            setItems(items.filter((item) => item.gameId !== id));
            const data = {
                gameId: id
            }
            deleteGame(data);
        } else if (type === "group") {
            setGroups(groups.filter((g) => g.id !== id));
            setItems(items.filter((item) => item.groupId !== id));
            const data = {
                groupId: id
            }
            deleteGameGroup(data);
        } else if (type === "item") {
            setItems(items.filter((i) => i.id !== id));
            const data = {
                itemId: id
            }
            deleteItem(data);
        }
    };

    const filteredItems = items.filter((i) => {
        if (selectedGroup !== null) {
            return (
                (i.groupId === selectedGroup) &&
                i.gameId === selectedGame
            );
        }
    });
    return (
        <Box p={3}><div style={{ marginBottom: "20px" }}>
            <DxButton
                text="Открыть Конвертер Валют"
                type="default"
                icon="money"
                onClick={openCurrencyPopup}
            />
        </div>

            {/* Popup for Currency Converter */}
            <Popup
                visible={isCurrencyPopupOpen}
                onHiding={closeCurrencyPopup}
                dragEnabled={false}
                showCloseButton={true}
                showTitle={true}
                title="Конвертер Валют"
                width={400}
            >
                <CurrencyConverter isOpen={isCurrencyPopupOpen} onClose={closeCurrencyPopup} />
            </Popup>
            {/* Games Table */}
            <Box mb={4}>
                <Typography variant="h6">Игры</Typography>
                <Button variant="contained" onClick={() => openDialog("game")} sx={{ mb: 1 }}>
                    <AddIcon />
                </Button>
                <DataGrid
                    rows={games}
                    density="compact"
                    hideFooterSelectedRowCount={true}
                    columnHeaderHeight={40} disableColumnSelector
                    rowHeight={50}
                    columns={[
                        {
                            field: "mainFileName",
                            headerName: "Баннер",
                            flex: 1,
                            renderCell: (params) => (
                                <img src={`${filePath}/Games/${params.row.mainFileName}`} alt="Game" width="40" height="40" />

                            ),
                        },
                        {
                            field: "fileName",
                            headerName: "Изображение",
                            flex: 1,
                            renderCell: (params) => (
                                <img src={`${filePath}/Games/${params.row.fileName}`} alt="Game" width="40" height="40" />

                            ),
                        },
                        { field: "name", headerName: "Название", flex: 2 },
                        { field: "description", headerName: "Описание", flex: 3 },
                        { field: "gameType", headerName: "Код Отправки SuperCell ID", flex: 1 },
                        { field: "sort", headerName: "Сортировка", flex: 1 },
                        {
                            field: "isActive",
                            headerName: "Активно",
                            flex: 1,
                            renderCell: (params) =>
                                params.row.isActive ? "Да" : "Нет",
                        },
                        {
                            field: "haveSuperCell",
                            headerName: "Есть SuperCell ID",
                            flex: 1,
                            renderCell: (params) =>
                                params.row.haveSuperCell ? "Да" : "Нет",
                        },
                        { field: "cDate", headerName: "Дата Добавления", flex: 1 },
                        {
                            field: "actions",
                            headerName: "Действия",
                            flex: 2,
                            renderCell: (params) => (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <Button
                                        color="warning"
                                        onClick={() => openDialog("game", params.row, true)}
                                    >
                                        <EditIcon />
                                    </Button>
                                    <Button
                                        color="error"
                                        onClick={() => deleteRecord(params.row.id, "game")}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </Box>
                            ),
                        },
                    ]}
                    pageSize={5}
                    onRowClick={(params) => {
                        setSelectedGame(params.row.id);
                        setSelectedGroup(null); // Clear group selection
                    }}
                />
            </Box>

            {/* Groups Table */}
            <Box mb={4}>
                <Typography variant="h6">Группы</Typography>
                <Button
                    variant="contained"
                    onClick={() => openDialog("group", { gameId: selectedGame }, false)}
                    sx={{ mb: 1 }}
                >
                    <AddIcon />
                </Button>
                <DataGrid
                    rows={groups.filter((g) => g.gameId === selectedGame)}
                    density="compact"
                    hideFooterSelectedRowCount={true}
                    columnHeaderHeight={40}
                    rowHeight={50}
                    disableColumnSelector={true} // Disables column selection
                    disableRowSelectionOnClick={true}

                    disableSelectionOnClick // Disables row selection when
                    columns={[
                        { field: "name", headerName: "Название", flex: 6 },
                        {
                            field: "isActive",
                            headerName: "Активно",
                            flex: 1,
                            renderCell: (params) =>
                                params.row.isActive ? "Да" : "Нет",
                        },
                        { field: "sort", headerName: "Сортировка", flex: 1 },
                        {
                            field: "actions",
                            headerName: "Действия",
                            flex: 2,
                            renderCell: (params) => (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <Button
                                        color="warning"
                                        onClick={() => openDialog("group", params.row, true)}
                                    >

                                        <EditIcon />
                                    </Button>
                                    <Button
                                        color="error"
                                        onClick={() => deleteRecord(params.row.id, "group")}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </Box>
                            ),
                        },
                    ]}
                    pageSize={5}
                    onRowClick={(params) => {
                        setSelectedGroup(params.row.id);
                    }}
                />
            </Box>

            {selectedGroup &&
                <Box>
                    <Typography variant="h6">Товары</Typography>
                    <Button
                        variant="contained"
                        onClick={() =>
                            openDialog("item", { groupId: selectedGroup, gameId: selectedGame })
                        }
                        sx={{ mb: 1 }}
                    >
                        <AddIcon />
                    </Button>
                    <DataGrid
                        rows={filteredItems}
                        density="compact"
                        hideFooterSelectedRowCount={true}
                        columnHeaderHeight={40}
                        rowHeight={50}
                        columns={[
                            {
                                field: "fileName",
                                headerName: "Изображение",
                                flex: 1,
                                renderCell: (params) => (
                                    <img src={`${filePath}/Games/${params.row.fileName}`} alt="Item" width="40" height="40"
                                    />



                                ),
                            },
                            { field: "name", headerName: "Название", flex: 1 },
                            { field: "description", headerName: "Описание", flex: 3 },
                            { field: "sort", headerName: "Сортировка", flex: 1 },
                            { field: "price", headerName: "Цена", flex: 1 },
                            { field: "discount", headerName: "Скидка", flex: 1 },
                            { field: "priceAfterDiscount", headerName: "Цена со скидкой", flex: 1 },
                            {
                                field: "isPromotion",
                                headerName: "Промо",
                                flex: 1,
                                renderCell: (params) =>
                                    params.row.isPromotion ? "Да" : "Нет",
                            },
                            { field: "promotionPeriod", headerName: "Промо Дни", flex: 1 },
                            {
                                field: "noLogin",
                                headerName: "Без входа",
                                flex: 1,
                                renderCell: (params) =>
                                    params.row.noLogin ? "Да" : "Нет",
                            },

                            {
                                field: "isLimited",
                                headerName: "Лимитированный",
                                flex: 1,
                                renderCell: (params) =>
                                    params.row.isLimited ? "Да" : "Нет",
                            },
                            {
                                field: "isNew",
                                headerName: "Новинка",
                                flex: 1,
                                renderCell: (params) =>
                                    params.row.isNew ? "Да" : "Нет",
                            },
                            {
                                field: "isPopular",
                                headerName: "Популярная",
                                flex: 1,
                                renderCell: (params) =>
                                    params.row.isPopular ? "Да" : "Нет",
                            },
                            { field: "cDate", headerName: "Дата Добавления", flex: 1 },
                            {
                                field: "actions",
                                headerName: "Действия",
                                flex: 2,
                                renderCell: (params) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <Button
                                            color="warning"
                                            onClick={() => openDialog("item", params.row, true)}
                                        >
                                            <EditIcon />
                                        </Button>
                                        <Button
                                            color="error"
                                            onClick={() => deleteRecord(params.row.id, "item")}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </Box>
                                ),
                            },
                        ]}
                        pageSize={5}
                    />
                </Box>
            }
            {/* Add/Edit Dialog */}
            <Dialog open={dialogOpen} onClose={closeDialog}>
                <DialogTitle>
                    {editMode
                        ? `Редактировать ${dialogType === "game"
                            ? "Игру"
                            : dialogType === "group"
                                ? "Группу"
                                : "Товар"}`
                        : `Добавить ${dialogType === "game"
                            ? "Игру"
                            : dialogType === "group"
                                ? "Группу"
                                : "Товар"}`}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="name"
                        label="Название"
                        value={formData.name || ""}
                        onChange={handleInputChange}
                        required
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        name="sort"
                        label="Сортировка"
                        type="number" // Makes the input field accept only numbers
                        value={formData.sort || 0}
                        onChange={handleInputChange}
                        required
                        sx={{
                            marginTop: "10px", // Optional additional margin
                        }}
                    />
                    {dialogType === "item" && (
                        <>
                            <TextField
                                fullWidth
                                margin="normal"
                                name="description"
                                label="Описание"
                                value={formData.description || ""}
                                onChange={handleInputChange}
                                required
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                name="price"
                                label="Цена"
                                type="number"
                                value={formData.price || ""}
                                onChange={handleInputChange}
                                required
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                name="discount"
                                label="Скидка"
                                type="number"
                                value={formData.discount || ""}
                                onChange={handleInputChange}
                            />
                            <Box>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (!file) return;
                                        const reader = new FileReader();
                                        reader.onloadend = () => {
                                            setFormData((prev) => ({
                                                ...prev,
                                                fileName: file.name,
                                                base64String: reader.result.split(",")[1],
                                            }));
                                        };
                                        reader.readAsDataURL(file);
                                    }}
                                    id="upload-button-fileName"
                                    style={{ display: "none" }} // Hides the default file input
                                />
                                <label htmlFor="upload-button-fileName">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        sx={{
                                            margin: "10px 0",
                                            padding: "10px 20px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        Загрузить изображение
                                    </Button>
                                </label>
                                {formData.fileName && (
                                    <Box sx={{ marginTop: "10px", textAlign: "center" }}>
                                        <Typography variant="subtitle1">Предпросмотр</Typography>
                                        <img
                                            src={
                                                formData.base64String
                                                    ? `data:image/jpeg;base64,${formData.base64String}`
                                                    : `${filePath}/Games/${formData.fileName}`
                                            }
                                            alt="Preview"
                                            style={{
                                                width: "216px",
                                                height: "140px",
                                                objectFit: "cover",
                                                borderRadius: "5px",
                                                border: "1px solid #ccc",
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </>
                    )}
                    {dialogType === "game" && (
                        <>
                            <TextField
                                fullWidth
                                margin="normal"
                                name="description"
                                label="Описание"
                                value={formData.description || ""}
                                onChange={handleInputChange}
                                required
                            />
                            <FormControl fullWidth margin="normal">
                                <Typography id="game-type-label">Игры для отправки SuperCell ID</Typography>
                                <Select
                                    labelId="game-type-label"
                                    name="gameType"
                                    value={formData.gameType || ""}
                                    onChange={handleInputChange}
                                    required
                                >
                                    {gameTypes.map((type) => (
                                        <MenuItem key={type.value} value={type.value}>
                                            {type.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Box>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (!file) return;
                                        const reader = new FileReader();
                                        reader.onloadend = () => {
                                            setFormData((prev) => ({
                                                ...prev,
                                                fileName: file.name,
                                                base64String: reader.result.split(",")[1],
                                            }));
                                        };
                                        reader.readAsDataURL(file);
                                    }}
                                    id="upload-button-fileName"
                                    style={{ display: "none" }} // Hides the default file input
                                />
                                <label htmlFor="upload-button-fileName">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        sx={{
                                            margin: "10px 0",
                                            padding: "10px 20px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        Загрузить изображение
                                    </Button>
                                </label>
                                {formData.fileName && (
                                    <Box sx={{ marginTop: "10px", textAlign: "center" }}>
                                        <Typography variant="subtitle1">Предпросмотр</Typography>
                                        <img
                                            src={
                                                formData.base64String
                                                    ? `data:image/jpeg;base64,${formData.base64String}`
                                                    : `${filePath}/Games/${formData.fileName}`
                                            }
                                            alt="Preview"
                                            style={{
                                                width: "64px",
                                                height: "64px",
                                                objectFit: "cover",
                                                borderRadius: "5px",
                                                border: "1px solid #ccc",
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                            <Box>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (!file) return;
                                        const reader = new FileReader();
                                        reader.onloadend = () => {
                                            setFormData((prev) => ({
                                                ...prev,
                                                mainFileName: file.name,
                                                mainBase64String: reader.result.split(",")[1],
                                            }));
                                        };
                                        reader.readAsDataURL(file);
                                    }}
                                    id="upload-button-mainFileName"
                                    style={{ display: "none" }} // Hides the default file input
                                />
                                <label htmlFor="upload-button-mainFileName">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        sx={{
                                            margin: "10px 0",
                                            padding: "10px 20px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        Загрузить баннер
                                    </Button>
                                </label>
                                {formData.mainFileName && (
                                    <Box sx={{ marginTop: "10px", textAlign: "center" }}>
                                        <Typography variant="subtitle1">Предпросмотр</Typography>
                                        <img
                                            src={
                                                formData.mainBase64String
                                                    ? `data:image/jpeg;base64,${formData.mainBase64String}`
                                                    : `${filePath}/Games/${formData.mainFileName}`
                                            }
                                            alt="Preview"
                                            style={{
                                                width: "326px",
                                                height: "216px",
                                                objectFit: "cover",
                                                borderRadius: "5px",
                                                border: "1px solid #ccc",
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.haveSuperCell || false}
                                        onChange={handleInputChange}
                                        name="haveSuperCell"
                                    />
                                }
                                label="Есть SuperCell ID"
                            />
                        </>
                    )}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.isActive || false}
                                onChange={handleInputChange}
                                name="isActive"
                            />
                        }
                        label="Активно"
                    />
                    {dialogType === "item" && (
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.noLogin || false} // Reflect the "No Group" state properly
                                        onChange={(e) => {
                                            handleInputChange(e);
                                            setFormData({
                                                ...formData,
                                                noLogin: e.target.checked,
                                            });
                                        }}
                                        name="noLogin"
                                    />
                                }
                                label="Без входа"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.isLimited || false}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                            setFormData({
                                                ...formData,
                                                isLimited: e.target.checked,
                                            });
                                        }}
                                        name="isLimited"
                                    />
                                }
                                label="Лимитированный"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.isNew || false}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                            setFormData({
                                                ...formData,
                                                isNew: e.target.checked,
                                            });
                                        }}
                                        name="isNew"
                                    />
                                }
                                label="Новинка"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.isPopular || false}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                            setFormData({
                                                ...formData,
                                                isPopular: e.target.checked,
                                            });
                                        }}
                                        name="isPopular"
                                    />
                                }
                                label="Популярная"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.isPromotion || false} // Reflect the "No Group" state properly
                                        onChange={(e) => {
                                            handleInputChange(e);
                                            setFormData({
                                                ...formData,
                                                isPromotion: e.target.checked,
                                            });
                                        }}
                                        name="isPromotion"
                                    />
                                }
                                label="Промоакция"
                            />
                            {formData.isPromotion && (
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    name="promotionDays"
                                    label="Количество дней промо"
                                    type="number"
                                    value={formData.promotionPeriod || ""}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        setFormData({
                                            ...formData,
                                            promotionPeriod: value ? parseInt(value, 10) : 0, // Ensure the value is a number
                                        });
                                    }}
                                    required
                                    sx={{ marginTop: "10px" }}
                                />
                            )}
                        </>

                    )}
                </DialogContent>
                {error && (
                    <Typography color="error" sx={{ pl: 2 }}>
                        {error}
                    </Typography>
                )}<DialogActions
                    sx={{
                        justifyContent: "flex-end", // Adjust button alignment
                        padding: "16px", // Add padding for spacing
                        backgroundColor: "#f5f5f5", // Optional background color for the footer
                    }}
                >
                    <Button
                        onClick={closeDialog}
                        sx={{
                            backgroundColor: "#f44336", // Red background
                            color: "#fff", // White text
                            fontWeight: "bold", // Bold text
                            padding: "8px 16px", // Adjust padding
                            borderRadius: "5px", // Rounded corners
                            "&:hover": {
                                backgroundColor: "#d32f2f", // Darker red on hover
                            },
                        }}
                    >
                        Отменить
                    </Button>
                    <Button
                        onClick={saveData}
                        sx={{
                            backgroundColor: "#4caf50", // Green background
                            color: "#fff", // White text
                            fontWeight: "bold", // Bold text
                            padding: "8px 16px", // Adjust padding
                            borderRadius: "5px", // Rounded corners
                            "&:hover": {
                                backgroundColor: "#388e3c", // Darker green on hover
                            },
                        }}
                    >
                        {editMode ? "Обновить" : "Сохранить"}
                    </Button>
                </DialogActions>

            </Dialog>
        </Box>
    );
};

export default Home;
